import Vue from 'vue'
import Vuex from 'vuex'

import { Users } from '@/store/users/users'
import { Permissions } from '@/store/permissions/permissions'
import { Auth } from '@/store/auth/auth'
import { Notifications } from '@/store/notifications/notifications'
import { Converge } from '@/store/converge/converge'
import { Emails } from '@/store/emails/emails'

import { SuperUsers } from '@/store/superusers/superusers'
import { Merchants } from '@/store/merchants/merchants'
import { Employees } from '@/store/employees/employees'

import { APIKeys } from "./api-keys/api-keys";
import { Transactions } from "./converge/transactions";
import { APINotifications } from "./notifications/api-notifications";

import { Security } from "./security/security";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
		APIKeys,
		APINotifications,
		Auth,
		Converge,
		Emails,
		Employees,
		Notifications,
		Merchants,
		Permissions,
		Security,
		SuperUsers,
		Transactions,
		Users
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {

  }
})