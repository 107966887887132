<script>
	// Extensions
	import View from '@/views/website/web-view'

	// Mixins to load sections dynamically
	import LoadSections from '@/mixins/website/load-sections'

	export default {
		name: 'WebHomeView',

		metaInfo: { title: 'Home' },

		extends: View,

		mixins: [
			LoadSections([
				'hero',
				'about-our-product',
				'features',
				'portal-features',
				'contact-us',
				'info',
				
			]),
		],

		props: {
			id: {
				type: String,
				default: 'home',
			},
		},
	}
</script>
