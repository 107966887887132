<template>
	<div>
		<template>
			<v-card 
				outlined
				elevation="3">
				<v-card-title class="mb-1 bg-primary text-white">
					<div class="row">
						<div class="col-4 flex items-center">
							<div class="title">
								Email Templates
							</div>
						</div>
						<div class="col-8 p-0 flex items-center">
							<div class="row">
								<div class="col-6 p-0">
									<v-select
										dark
										:items="Emails.emailTemplateList"
										@change="loadTemplate"
										item-text="title"
										item-value="title"
										label="Select Template"
									></v-select>
								</div>
								<div class="col-6 p-0 flex justify-end items-center">
									<v-btn 
										class="mx-5"
										color="accent"
										@click="newTemplate">
										New
									</v-btn>
									<v-btn 
										class="mx-5"
										color="error"
										@click="testButton">
										Delete
									</v-btn>

								</div>
							</div>
						</div>
					</div>
					
				</v-card-title>
				<v-form
						ref="validSubjectRef"
						v-model="validSubject">
					<div class="row mt-3">
						<div class="col-12">
							<div class="field-title pb-0">
								Subject:
							</div>
							<div class="field-content p-0">
								<v-text-field
									class="pt-0"
									outlined
									filled
									:rules="[rules.required]"
									background-color="blue-grey lighten-5"
									v-model="templateForm.subject">
								</v-text-field>
							</div>
						</div>
					</div>
				</v-form>
				
				<div class="row">
					<div class="col-9">
						<div class="scroll-container">
							<DxScrollView
								:scroll-by-content="true"
								:scroll-by-thumb="true"
								show-scrollbar="onHover"
								height="70vh"
							>
							<div v-if="templateForm.title" class="row justify-center my-2">
								<div class="col-6 text-center text-xl">
									{{ templateForm.title }}
								</div>
							</div>
							<div v-else class="row justify-center my-2">
								<div class="col-6 text-center text-xl">
									Click "New" or "Select Template"
								</div>
							</div>
							  <editor
									:api-key="APIKeys.tinyMCE"
									cloud-channel="5"
									:disabled=false
									initial-value="Enter data..."
									:inline=false
									v-model="templateForm.body"
									:init="{
										height: 500,
										menubar: true,
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'insertdatetime media table paste code help wordcount'
										],
										toolbar:
											'undo redo | formatselect | bold italic backcolor | \
											alignleft aligncenter alignright alignjustify | \
											bullist numlist outdent indent | removeformat | help'
									}"
								/>

				
							</DxScrollView>
						</div>
					
					</div>
					<div class="col-3">
						<div class="scroll-container">
							<DxScrollView
								:scroll-by-content="true"
								:scroll-by-thumb="true"
								show-scrollbar="onHover"
								height="100%"
							>
								<div class="title mb-3 border-b-2 border-gray-600">
									Merge Tags
								</div>
								<div class="field-title text-xl divider bg-gray-400">
									Customers
								</div>
								<div class="field-content">
									<div class="text-sm">first_name</div>
									<div class="text-sm">last_name</div>
									<div class="text-sm">amount_paid</div>
								</div>
								<div class="field-title text-xl divider bg-gray-400">
									Merchants
								</div>
								<div class="field-content">
									<div class="text-sm">dba_name</div>
									<div class="text-sm">legal_name</div>
								</div>
								<div class="field-title text-xl divider bg-gray-400">
									Employees
								</div>
								<div class="field-content">
									<div class="text-sm">first_name</div>
									<div class="text-sm">last_name</div>
								</div>
							</DxScrollView>
						</div>
					</div>
				</div>
				<div class="row justify-end my-2">
					<div class="col-6 text-end">
						<v-btn
							color="success"
							@click="saveTemplate">
							Save Template
							<v-progress-circular
								class="mx-3"
								indeterminate
								color="error"
								v-if="spinner"
							></v-progress-circular>
						</v-btn>
						<v-btn
							v-if="$can('view', 'superuser')"
							class="my-3 mx-3"
							color="success"
							@click="testButton">
							Get Data
						</v-btn>
					</div>
				</div>

			</v-card>
			
		</template>

		<div class="row py-10">
			
		</div>


		<!-- Popups and Modals -->
		<template>
			<v-dialog
				v-model="confirmNewDialog"
				persistent
				max-width="290"
			>
				<template v-slot:activator="{ on, attrs }">
				</template>
				<v-card>
					<v-card-title class="headline">
					Create new Template
					</v-card-title>
					<v-card-text>
						<v-text-field
							label="Template Name"
							v-model="templateForm.title">
						</v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="green darken-1"
							text
							@click="confirmNewDialog = false"
						>
							Cancel
						</v-btn>
						<v-btn
							color="green darken-1"
							text
							@click="startNewTemplate"
						>
							Start
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>

		<!-- Dialog -->
		<template>
			<v-dialog
      v-model="successDialog"
      persistent
      max-width="290"
    	>
       <v-card>
        <v-card-title class="headline">
          Success
        </v-card-title>
        <v-card-text>
					Template Successfully Created
				</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
           	Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		</template>

		

	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'


import Editor from '@tinymce/tinymce-vue'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

export default {
  name: 'emailTemplatesComponent',
  components: {

		DxScrollView,
		'editor': Editor
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			confirmNewDialog: false,
			successDialog: false,
			validSubject: false,
			spinner: false,

			//Email Template Data
			templateForm: {
				title: null,
				subject: null,
				body: null
			}
			
    }
  },
  methods: {
    testButton () {
			console.log('Emails.emailTemplateList', this.Emails.emailTemplateList)
		},
		getData() {
			console.log('this.templateForm.body', this.templateForm.body)
		},
			//Templates
		newTemplate() {
			console.log("newTemplate")
			this.confirmNewDialog = true
		},
		startNewTemplate() {
			console.log("startNewTemplate")
			this.confirmNewDialog = false
			this.templateForm.body = ""
		},
		async saveTemplate() {
			console.log("saveTemplate")
			console.log("this.templateForm.body, ", this.templateForm.body)
			const validSub = this.$refs.validSubjectRef.validate()
			if(validSub) {
				this.spinner = true
				await this.$store.dispatch("SaveEmailTemplate", this.templateForm)
				this.spinner = false
				this.successDialog = true
				this.clearForm()
				
			} else {
				// this.subjectDialog = true
				this.spinner = false
			}

			//Make JS Blob
			// const blob = new Blob([this.templateForm.body], {type: 'text/html'})
			// console.log("blob", blob)
			// console.log("blob.text()", blob.text())

			//Convert blob to base64
			// var reader = new FileReader();
			// reader.readAsDataURL(blob);
			// reader.onload = (e) => {
			// 	this.templateForm.body = e.target.result;
			// 	console.log('this.templateForm1', this.templateForm)
			// 	console.log('this.templateForm2', this.templateForm)
			// 	this.$store.dispatch("SaveEmailTemplate", this.templateForm)
			// };

			// var htmlDoc = new File([blob], "email-template.html", {
			// 	type: "text/html",
			// });
			// console.log("htmlDoc", htmlDoc)

			// var formData = new FormData();
			// formData.append("body", htmlDoc);
			// formData.append("title", this.templateForm.title);

			
			
		},
		loadTemplate(evt) {
		console.log('loadTemplate evt', evt)
			const tempIndx = this.Emails.emailTemplateList.findIndex((item) => item.title = evt)
			console.log('tempIndx', tempIndx)
			const obj = this.Emails.emailTemplateList[tempIndx]
			this.templateForm.title = obj.title
			this.templateForm.body = obj.body
			this.templateForm.subject = obj.subject

		},
		clearForm() {
			for(let key in this.templateForm) {
				this.templateForm[key] = null
			}
		}

  },
  computed: {
    ...mapState(["APIKeys", "Emails"]),
    ...mapGetters(["Emails.emailTemplateList"])

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">
.scroll-container {
	height: calc(90vh - 265px)
}
</style>
