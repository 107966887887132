<template>
	<div class="flex justify-center">
		<div class="container mt-4">
			<DxScrollView
				show-scrollbar="always"
				width="100%"
				direction="horizontal"
				:scroll-by-thumb="true">

				<!-- Main Container Row -->
				<div class="row">
					<!-- Main Content Left Area -->
					<v-col cols="12" lg="6" class="px-2">
			
							<baseUserFormComponent
								ref="baseSuperUserFormRef"
								@UserData="GetUserData"
								@updateSuperuser="updateSuperuser"
								@clearCheckbox="clearCheckbox">
								<template slot="title">
									Add New Superuser
								</template>

								<template slot="profile-info">
									<div class="row">
										<div class="col-12">
											<v-file-input
												accept="image/*"
												label="File input"
												v-model="profile_img"
											></v-file-input>
										</div>
									</div>
								</template>

							</baseUserFormComponent>
						
					</v-col>
					<!-- END Main Content Left Area -->
					<!-- Right Panel -->
					<v-col cols="12" lg="6" class="px-2">
						<template>
							<v-card>
								<v-card-title class="mb-1 bg-primary text-white">
									<v-row class="p-2">
										<v-col cols=6>
											<div class="title">
												Superuser List
											</div>
										</v-col>
										<div class="col-6 flex items-center">
											<v-text-field
												class="m-0 p-0"
												color="teal"
												dark
												v-model="search"
												append-icon="mdi-magnify"
												label="Search"
												single-line
												hide-details
											></v-text-field>
										</div>
									</v-row>
								</v-card-title>

								<dataTableComponent
									ref="superUserDataTableRef"
									:headerTitles="headers"
									:searchData="search"
									:listData="listData.list"
									@updateItem="updateItem"
									@clearData="clearData"
									@deleteItem="deleteItem">
								</dataTableComponent>

							</v-card>

						</template>
				

					</v-col>
					<!-- END Right Panel -->
				</div>
				<!-- END Main Container Row -->

			</DxScrollView>
				
		</div>
	</div>
</template>

<script>
"use strict";
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

//Components
import baseUserFormComponent from "@/components/forms/base-user-form"
const dataTableComponent = import("@/components/tables/data-table-component")
import parentSelectionComponent from "@/components/parent-selectors/parent-merchant-select-component"

import LoadingState from '@/components/component-management/loading-state-component'
import ErrorState from '@/components/component-management/error-state-component'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'

export default {
	name: 'superUserManageSuperuser',
	metaInfo: { title: 'Add Superusers' },
  components: {
		DxScrollView,
		baseUserFormComponent,
		parentSelectionComponent,
		dataTableComponent: () => ({
			component: dataTableComponent,
			loading: LoadingState,
			error: ErrorState,
			//Delay before showoing loader
			delay:100,
			//Timeout to show error component
			timeout: 5000
		}),
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
      // Main Data

      // Component Data
			search: '',
			headers: [
				{	text: "ID",	align: 'center',	sortable: true,	value: 'id' },
				{ text: 'Name', align: 'center',	sortable: true, value: 'user_obj.full_name' },
				{ text: 'Email', align: 'center',	sortable: true, value: 'user_obj.email' },
				{ text: 'Phone', align: 'center',	sortable: true, value: 'user_obj.mobile_phone' },
				{ text: 'Last Login', align: 'center',	sortable: true, value: 'user_obj.last_login' },
				{ text: 'Is Active', align: 'center',	sortable: true, value: 'user_obj.is_active' },
			],

			listData: {
				tableId: "superuserListTable",
				list: new Array()
			},

			// Form Data
			profile_img: null,
			superuserForm: {
				id: null,
				user: null,
				profile_img: null,
			},
			baseUserForm: null

    }
  },
  methods: {
    testButton() {
			this.$refs.baseSuperUserFormRef.reset()
		},
		clearCheckbox(evt) {
			// console.log("clearCheckbox", evt);
			// console.log("this.$refs", this.$refs);
			this.$refs.superUserDataTableRef.clearItem()
		},
		GetUserData(evt) {
			// console.log("GetUserData evt", evt)
			this.baseUserForm = evt
			// console.log("baseUserForm", this.baseUserForm)
		},
		async updateItem(evt) {
			// console.log('updateItem evt', evt)

			var getUserObj
			getUserObj = await this.$store.dispatch("GETSuperUserProfileById", {id: evt.id});
			// console.log('getUserObj', getUserObj);
			this.superuserForm.id = evt.id
			this.superuserForm.profile_img = evt.profile_img

			this.$refs.baseSuperUserFormRef.updateForm(getUserObj)
			this.$store.commit("SET_SELECTED_USER", getUserObj)
			
		},
		async createUser(form) {
			return new Promise(async(resolve, reject) => {
				//Create User account first, Superuser second
				// console.log("Superuser CreateUser")
				try {
					// console.log("form", form)
					
					//By the time the user is created, the image is attached
					if(this.profile_img) {
						var profileReader = new FileReader();
						profileReader.readAsDataURL(this.profile_img);
						profileReader.onload = (e) => {
							this.superuserForm.profile_img = e.target.result;
						};
					}

					//Create Base User First
					// console.log("Create User this.userForm", this.userForm);
					let userResponse = await this.$store.dispatch("POSTUser", form)
					// console.log("userResponse", userResponse)

					if(userResponse.status === 200 || userResponse.status === 201) {
						// console.log('this.superuserForm', this.superuserForm)
						this.superuserForm.user = userResponse.data.id

						//Create Superuser Account
						let superuser = await this.$store.dispatch("POSTSuperUser", this.superuserForm)
						// console.log("superuser", superuser);
						// console.log("this.$refs", this.$refs);
						
						
						if(superuser.status === 200 || superuser.status === 201) {
							this.$refs.baseSuperUserFormRef.resetForm()
							this.baseUserForm = null

							// Update Table list
							await this.$store.dispatch('GETSuperUserList')
							resolve()
						}
					}

				} catch(error) {
					// console.error('error', error)
					reject("There was an error creating superuser", error)
				}
			})
		},
		async updateSuperuser(baseuser) {
			// console.log('updateSuperuser ')
			this.superuserForm.user = baseuser.id
			await this.$store.dispatch("PATCHSuperuser", this.superuserForm)
			await this.$store.dispatch('GETSuperUserList')
		},
		async deleteItem(evt) {
			// console.log('superusers deleteItem evt', evt)
			const userObj = {id: evt.user, is_active: false}
			let response = await this.$store.dispatch("PATCHBaseUser", userObj)
			// console.log('delete response', response)
			if(response.status === 200 || response.status === 201) {
				let superUsers = await this.$store.dispatch("GETSuperUserList")
				this.listData.list = superUsers
			}
		},
		clearData() {
			// console.log("ClerDataParentSuperuer")
			// console.log("this.baseUserForm", this.baseUserForm);
			// console.log("this.userForm", this.userForm);
			// console.log("superuserForm", this.superuserForm);
			// console.log("this.$parent", this.$parent);
			// console.log("this.$refs", this.$refs);

			for(let key in this.superuserForm) {
				this.superuserForm[key] = null
			}
			this.baseUserForm = null

			this.$refs.baseSuperUserFormRef.resetForm()
			
		},
		mountData() {
			this.listData.list = this.GET_SUPERUSER_LIST
		},

  },
  computed: {
    ...mapState(["Notifications"]),
    ...mapGetters(["GET_SUPERUSER_LIST"])

	},
	created() {
		// console.log('this.GET_SUPERUSER_LIST', this.GET_SUPERUSER_LIST)
		this.listData.list = this.GET_SUPERUSER_LIST
		// console.log('this.listData', this.listData)
	},
  mounted () {

  }
}
</script>
<style scoped lang="scss">

</style>
