import Vue from 'vue';
var numeral = require("numeral");
// http://numeraljs.com/


// switch between locales
numeral.locale('en');

Vue.filter("number", (value) => {
  return numeral(value).format("0.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("dollar", (value) => {
  return numeral(value).format(	'$ 0,0.00'); 
});

Vue.filter("percent", (value) => {
  return numeral(value).format(	'%0.00'); 
});

Vue.filter('toLowerCase', (value) => {
  return value.toLowerCase();
});

Vue.filter('toFloat', (value) => {
  return parseFloat(value);
});

Vue.filter('phoneNumber', (value) => {
	return value.replace(/[^0-9]/g, '')
							.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('capitalize', (value) => {
	// console.log('value', value.toString())
	if(value) {
		let val = String(value)
		return val.charAt(0).toUpperCase() + val.slice(1)
	}
	
});

Vue.filter('last4', (value) => {
	if(value) {
		return value.slice(value.length - 4)
	}

});


//Make a filter to format the Permissions and module code name