import Vue from 'vue'
import axios from 'axios'

import apiRoutes from '@/js/api-routes'

export const Emails = {
  namespace: true,
  name: 'emails',
  state: {
		emailSettings: {},
		emailTemplateList: [],
		emailList: []
  },
  mutations: {
		SET_EMAIL_LIST(state, payload) {
			state.emailList = payload
		},
		SET_EMAIL_TEMPLATES(state, payload) {
			// console.log("SET_EMAIL_TEMPLATES payload", payload)
			// Vue.set(state.emailTemplateList, state.emailTemplateList.length, payload)
			state.emailTemplateList = payload
		},
		SET_EMAIL_SETTINGS(state, payload) {
			// console.log('SET_EMAIL_SETTINGS', payload)
			Vue.set(state, 'emailSettings', payload)
		},
		PUSH_EMAIL_TEMPLATE(state, payload) {
			state.emailTemplateList.push(payload)
		}
  },
  actions: {
		async POSTEmailSetting({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'email-settings';
					let type = 'Create New Email Account';
					let response = await apiRoutes.POSTItem(dispatch, rootState,payload, endpoint, type)
					// console.log('POSTEmailSetting response', response);
					if(response.status === 201) {
						// console.log("POSTEmailSetting Successful");
						commit('SET_EMAIL_SETTINGS', response.data);
						resolve(response)
					} else {
						reject(response)
					}
				} catch (error) {
					reject(error)
				}       
			}).catch(error => {
				return error;
			});
		},
		// Check to see if user's email exists to an account
		verifyUserAccount({ dispatch, commit }, payload) {
			return new Promise( async (resolve, reject) => {
				// console.log("payload", payload);
				// console.log("Users email addres for password reset", payload);
				// axios.get(`/django/users-list/?search=${payload.email}`)
				axios.post(`/django/user-verify/`, payload)
				.then(response => {
					// console.log("passwordResetEmail to Node - response:", response);
					if(response.data.length > 0) {
						resolve(true)
					}
					resolve(false)
				}).catch(error => {
					// dispatch('updateNotification', error.response)
					// console.log('error', error.response)
					resolve(error)

				});
			})
		},
		// //Password Reset Email with Link - Email is sent through Django 
		passwordResetEmail({ dispatch, commit }, payload) {
			// console.log("payloafd", payload);
			return new Promise( async (resolve, reject) => {
				payload['location'] = window.location.origin
				// console.log("Users email addres for password reset", payload);
				axios.post('/node/email/reset-password', payload)
				.then(response => {
					// console.log("passwordResetEmail to Node - response:", response);
					if(response.status == 200 || response.status == 201) {
						resolve(response.data)
					}
				}).catch(error => {
					// dispatch('updateNotification', error.response)
					// console.log('error', error.response)
					resolve(error)

				});
			})
		},
		//Set New Password
		setNewPassword({ dispatch, commit }, payload) {
			return new Promise( async (resolve, reject) => {
				// console.log("Users email addres for password reset", payload);
				axios.post('/django/forgot-pw/', payload)
				.then(response => {
					dispatch('updateNotification', response)
					if (response.status === 200 || response.status === 201) {
						// console.log("passwordResetEmail to Node - response:", response);
						resolve(response)
					}
				}).catch(error => {
					// dispatch('updateNotification', error.response)
					// console.log('error', error.response)
					resolve()
				});
			})
		},
		// Save Email to Database
		async SaveEmail({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'email-list';
					let type = 'Create New Email Account';
					let response = await apiRoutes.POSTItem(dispatch, rootState,payload, endpoint, type)
					// console.log('POSTEmailSetting response', response);
					if(response.status === 201) {
						// console.log("POSTEmailSetting Successful");
						commit('SET_EMAIL_SETTINGS', response.data);
						resolve(response)
					} else {
						reject(response)
					}
				} catch (error) {
					reject(error)
				}       
			}).catch(error => {
				return error;
			});
		},

    // SET Nodemailer SMTP Settings in Node
		SetEmailSettings({ commit, dispatch, state }) {
			// console.log('SetEmailSettings')
			return new Promise(async (resolve, reject) => {
				try {
					axios.get("/node/email/save-settings").then((response)=> {
						// console.log('response', response)
						resolve(response)

					}).catch(error => {
						// console.error('axios error', error);
						resolve(error)
					})
				} catch(error) {
					// console.error('promise error', error);
				}
			})
		},
		SendTestEmail({ commit, dispatch, state }, payload) {
			// console.log('SendTestEmail payload', payload)
			return new Promise(async (resolve, reject) => {
				try {

					axios.post("/node/email/test", payload).then((response)=> {
						// console.log('response', response)
						resolve(response)

					}).catch(error => {
						// console.error('axios error', error.response);
						if(error.response.status === 400 || error.response.status === 500) {
							resolve(error.response)
						}
						
					})
				} catch(error) {
					// console.error('promise error', error);
				}
			})
		},
		SendyEmailServiceNode({ commit, dispatch, state }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					axios.post("/node/email/sendy-email", payload).then((response)=> {
						// console.log('response', response)
						resolve(response)

					}).catch(error => {
						// console.error('axios error', error.response);
						if(error.response.status === 400 || error.response.status === 500) {
							resolve(error.response)
						}
						
					})
				} catch(error) {
					// console.error('promise error', error);
				}
			})
		},
		SendyEmailService({ commit, dispatch, state }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					axios({
						method: 'post',
						url: "https://mymailmaker.com/subscribe", 
						data: payload,
						headers: {
							"content-type":	"application/x-www-form-urlencoded;charset=utf-8"
						}
					}).then((response)=> {
						// console.log('response', response)
						resolve(response)

					}).catch(error => {
							// console.error('error', error);
							// console.error('error.response', error.response);
							if(error.response.status === 400 || error.response.status === 500) {
								resolve(error.response)
							}
					})
				} catch(error) {
					// console.error('promise error', error);
				}
			})
		},
		//Send Emails with Template in html:data
		SendEmail({ commit, dispatch, state }, payload) {
			// console.log('SendEmail payload', payload)
			return new Promise(async (resolve, reject) => {
				try {

					axios.post("/node/email/send", payload).then((response)=> {
						// console.log('response', response)
						resolve(response)

					}).catch(error => {
						// console.error('axios error', error);
						reject(error)
					})
				} catch(error) {
					// console.error('promise error', error);
				}
			})
		},
		//Customer Receipt Email
		SendReceipt({ commit, dispatch, state }, payload) {
			// console.log('SendReceipt payload', payload)
			return new Promise(async (resolve, reject) => {
				try {
					axios.post("/node/email/customer-receipt", payload).then((response)=> {
						// console.log('response', response)
						resolve(response)

					}).catch(error => {
						// console.error('axios error', error.response);
						resolve(error.response)
					})
				} catch(error) {
					// console.error('promise error', error);
				}
			})
		},
		//Merchant Transaction Notification Email
		SendTransNotification({commit, dispatch, rootState}, payload) {
			// console.log('SendTransNotification payload', payload)
			return new Promise(async (resolve, reject) => {
				try {
					axios.post("/node/email/transaction-notification", payload).then((response)=> {
						// console.log('response', response)
						resolve(response)

					}).catch(error => {
						// console.error('axios error', error.response);
						resolve(error.response)
					})
				} catch(error) {
					// console.error('promise error', error);
				}
			})
		},
		SaveEmailTemplate({commit, dispatch, rootState}, payload) {
			// console.log('SendEmail payload', payload)
			return new Promise(async (resolve, reject) => {
				try {
					let endpoint = 'emails-template/';
					let type = 'Save Email Template';
					let response = await apiRoutes.POSTItem(dispatch, rootState, payload, endpoint, type)
					// console.log('SaveEmailTemplate response', response);
					if(response.status === 201) {
						// console.log("SaveEmailTemplate Successful");
						commit('PUSH_EMAIL_TEMPLATE', response.data);
						resolve(response)
					} else {
						reject(response)
					}
				} catch (error) {
					reject(error)
				}
			})
		},
		//GET LISTS
		async GETEmailList({commit, dispatch, rootState}) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'email-list/';
					let type = 'Get Email List';
					let response = await apiRoutes.GETList(dispatch, rootState, endpoint, type);
					// console.log('GETEmailList response', response);
					commit('SET_EMAIL_LIST', response.data);
					resolve(response.data)

				} catch(error) {
					// console.error('TryCatch Error error', error)
					reject(error)
				}
			}).catch(error => {
				// console.error('Promise Error error', error)
				return error;
			});
		},
		async GETEmailTemplateList({commit, dispatch, rootState}) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'emails-template/';
					let type = 'Get Email Templates';
					let response = await apiRoutes.GETList(dispatch, rootState, endpoint, type);
					// console.log('GETEmailTemplateList response', response);
					commit('SET_EMAIL_TEMPLATES', response.data);
					resolve(response.data)

				} catch(error) {
					// console.error('TryCatch Error error', error)
					reject(error)
				}
			}).catch(error => {
				// console.error('Promise Error error', error)
				return error;
			});
		},
		async GETEmailSettings({commit, dispatch, rootState}) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'email-settings/';
					let type = 'Get Email Settings';
					let response = await apiRoutes.GETList(dispatch, rootState, endpoint, type);
					// console.log('GETEmailSettings response', response);
					commit('SET_EMAIL_SETTINGS', response.data);
					resolve(response.data)

				} catch(error) {
					// console.error('TryCatch Error error', error.response)
					resolve(error.response)
				}
			}).catch(error => {
				// console.error('Promise Error error', error)
				return error;
			});
		},
	

  },
  getters: {
		GET_EMAIL_LIST(state) {
			return state.emailList
		},
		GET_TEMPLATE_LIST(state) {
			return state.emailTemplateList
		},
		GET_EMAIL_SETTINGS(state) {
			return state.emailSettings
		}
  }
}
