
<template>
	<div class="flex justify-center">
		<div class="container">
			<!-- Main Container Row -->
			<div class="row">
				<!-- Main Content Left Area -->
				<div class="col-12">						
					<template>
						<v-expansion-panels>
							<!--  Email -->
							<v-expansion-panel>
								<v-expansion-panel-header>Email Settings</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div class="row">
										<v-col cols="6">
											<v-text-field
												label="SMTP Host"
												placeholder="smtp.gmail.com"
												v-model="emailForm.smtpHost">
											</v-text-field>
										</v-col>
										<v-col cols="6">
											<v-text-field
												label="SMTP Port"
												placeholder="587"
												v-model="emailForm.smtpPort">
											</v-text-field>
										</v-col>
										<v-col cols="6">
											<v-switch
												v-model="emailForm.TLS"
												label="TLS"
											></v-switch>
										</v-col>
										<v-col cols="6">
											<v-switch
												v-model="emailForm.SSL"
												label="SSL"
											></v-switch>
										</v-col>
										<v-col cols="6">
											<v-text-field
												label="Username"
												placeholder="jane@janedoe.com"
												v-model="emailForm.username">
											</v-text-field>
										</v-col>
										<v-col cols="6">
											<v-text-field
												label="Password"
												placeholder="••••••••"
												v-model="emailForm.password"
												type="password">
											</v-text-field>
										</v-col>
									</div>
									
									<div class="row">
										<v-col col="12" sm="6">
											<v-btn
												@click="saveEmailSettings"
												color="primary">
												Save Settings
											</v-btn>
											<!-- <v-btn
												@click="sendReceiptEmail"
												color="primary"
												class="mx-3"
												>
												Send Test Email
											</v-btn> -->
											<v-btn
												@click="sendTestEmail"
												color="primary"
												class="mx-3"
												>
												Send Test
											</v-btn>
											<v-btn
												@click="sendMerchantNotification"
												color="primary"
												>
												Notify Merchant
											</v-btn>
										</v-col>
									</div>
									<div class="row mt-12 bg-gray-400" v-if="isSavedSettings">
										<div class="col-6">
											<v-text-field
												label="Send to:"
												placeholder="New Email Address"
												v-model="testEmailAddress">
											</v-text-field>
										</div>
										<div class="col-6 flex items-center">
											<v-btn
												@click="sendTestEmail"
												color="primary">
												<span>Send Test</span>
												<v-progress-circular
													v-if="spinner"
													class="mx-2"
													indeterminate
													color="warning"
												></v-progress-circular>
											</v-btn>
										</div>
										
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>

							<!-- ApiKeys Captcha -->
							<v-expansion-panel>
								<v-expansion-panel-header>Captcha API</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-form
										ref="validCaptchaFormRef"
										v-model="validCaptchaForm">
										<div class="row">
											<v-col cols="6">
												<v-select
													:rules="[rules.required]"
													label="Company"
													:items="companyList"
													v-model="captchaForm.company">
												</v-select>
											</v-col>
											<v-col cols="6">
												<v-select
													:rules="[rules.required]"
													label="Service"
													:items="serviceList"
													v-model="captchaForm.service">
												</v-select>
											</v-col>
		
											<v-col cols="6">
												<v-text-field
													:rules="[rules.required]"
													label="Key"
													v-model="captchaForm.key">
												</v-text-field>
											</v-col>
											<v-col cols="6">
												<v-text-field
													:rules="[rules.required]"
													label="Secret"
													v-model="captchaForm.secret">
												</v-text-field>
											</v-col>
										</div>
									</v-form>
									
									
									<div class="row">
										<v-col col="12" sm="6">
											<v-btn
												@click="saveCaptchaSettings"
												color="primary">
												Save Settings
											</v-btn>
										</v-col>
									</div>

								</v-expansion-panel-content>
							</v-expansion-panel>

						</v-expansion-panels>

						
					</template>

				</div>
				<!-- END Main Content Left Area -->
			</div>
			<!-- END Main Container Row -->
		</div>

		<!-- Dialog -->
			<v-dialog
				v-model="dialog"
				width="25%"
			>
				<v-card>
					<v-card-title class="headline-3 grey lighten-2">
						{{ dialogTitle }}
					</v-card-title>

					<v-card-text class="text-center">
						{{ dialogMessage }}
					</v-card-text>

					<v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
				
				</v-card>
			</v-dialog>
		
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { FormMixins } from "@/mixins/form-mixins"

import moment from 'moment'
import axios from "axios"

export default {
	name: 'portalSettings',
	metaInfo: { title: 'Site Settings' },
  components: {
  },
  mixins: [
		FormMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			spinner: false,
			dialog:false,
			dialogTitle: "",
			dialogMessage: "",
			
			//Email Form Settings
			isSavedSettings: false,
			emailForm: {
				smtpHost: "",
				smtpPort: '',
				TLS: true,
				SSL: true,
				username: '',
				password: '',			
			},
			testEmailAddress: '',

			//Captcha API Settings Form
			validCaptchaForm: false,
			captchaForm: {
				company: null,
				service: null,
				key: null,
				secret: null,

			},
			serviceList: [
				'CAPTCHAV3'
			],
			companyList: [
				'GOOGLE'
			]



    }
  },
  methods: {
    testButton () {
			// console.log("this.Shipping.USPSCredentials", this.Shipping.USPSCredentials)
			// console.log("this.Shipping.USPSMethods", this.Shipping.USPSMethods)
		},
		async saveEmailSettings() {
			// console.log('saveEmail')
			
			//Save to Django 
			let email = await this.$store.dispatch("POSTEmailSetting", this.emailForm)
			if(email.status === 201) {
				this.isSavedSettings = true
			}
			
			//Save to Node and Use to send emails
			this.$store.dispatch("SetEmailSettings", this.emailForm)
		},
		async sendTestEmail() {
			// console.log('testEmail')
			this.spinner = true
			// console.log("this.GET_USER_PROFILE.full_name", this.GET_USER_PROFILE.full_name)
			let email = await this.$store.dispatch("SendTestEmail", {emailAddress: this.testEmailAddress, name:this.GET_USER_PROFILE.full_name})
			this.spinner = false
			// console.log('email', email)
			if(email.status === 200 || email.status === 201 ) {
				this.dialogTitle = "Success"
				this.dialogMessage = email.data
				this.dialog = true
			} else if(email.status === 400) {
				this.dialogTitle = "Error"
				this.dialogMessage = email.data
				this.dialog = true
			} else if(email.status === 500) {
				this.dialogTitle = "Error"
				this.dialogMessage = email.statusText
				this.dialog = true
			}
		},
		// NOT working, Do not use
		async sendyAnEmail() {

			// Set up payload Object
			var payload = {
				api_key: "Yer9t9aRcwW0jiFPeokV",
				customer_name: "Ian Christensen",
				customer_email: "ian@datacommerchantservices.com",
				email: "ian@datacommerchantservices.com",
				list: "xjCPZ0YDagrfDnD8busB6w",
				total_paid: "14.45",
				transaction_id: "23455-23432",
				Date: "25/12/2022",
				boolean: true
			}

			// Send email
				let email = await this.$store.dispatch("SendyEmailServiceNode", payload)
				// console.log('email', email);
			// Receive response
		},
		sendReceiptEmail() {
			// console.log('this.GET_EMAIL_SETTINGS', this.GET_EMAIL_SETTINGS)
			const emailObj = Object.assign({}, 
			{
				merchant_email: "ian@datacomnetsol.com"
				})
			this.$store.dispatch("SendTransNotification", emailObj)
		},
		sendMerchantNotification() {
			// console.log('this.GET_EMAIL_SETTINGS', this.GET_EMAIL_SETTINGS)
			const emailObj = Object.assign({}, 
			{
				merchant_email: "ian@datacomnetsol.com"
				})
			this.$store.dispatch("SendTransNotification", emailObj)
		},
		async saveCaptchaSettings() {
			// console.log("saveCaptchaSettings")
			this.$refs.validCaptchaFormRef.validate()
			if(this.validCaptchaForm) {
				let response = await this.$store.dispatch("POSTAPIKey", this.captchaForm)
				if(response) {
					this.$store.dispatch("notificationPopup", {title: "Success", body: "Updated"})
				}
			}
		
		}

  },
  computed: {
    ...mapState(["Shipping", "Notifications"]),
    ...mapGetters(["GET_USER_PROFILE"])

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">

</style>
