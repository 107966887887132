<template>
	<div class="flex justify-center">

		<modalComponent
			:openModal="openModal"
			@closeModal="closeModal">
		
			<template slot="title">
				<div>
					View Email
				</div>
			</template>

			<template slot="body">
				<div>
					<p>Here is your email...</p>
				</div>
			</template>
		</modalComponent>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

//Components
import modalComponent from "@/components/popups/modal-component"

export default {
  name: 'viewEmailModal',
  components: {
		modalComponent
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			openModal: false,
			
			//HTML Editor
			markup: "",
      sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
      fontValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true

 

    }
  },
  methods: {
    testButton () {

		},
		closeModal(evt) {
			this.openModal = evt
		}

  },
  computed: {
    ...mapState([]),
    ...mapGetters([])

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">
.dx-htmleditor-content img {
    vertical-align: middle;
    padding-right: 10px;
}

.options {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    margin-top: 20px;
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}
</style>
