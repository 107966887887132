<template>
	<div class="flex justify-center">

		<modalComponent
			:openModal="openModal"
			@closeModal="closeModal">
		
			<template slot="title">
				<div>
					New Email
				</div>
			</template>

			<template slot="body">
				<div class="row">
					<div class="col-12">
						<template>
							<v-form v-model="valid">
								<v-row>
									<v-col
										cols="12"
										md="4"
									>
										<div class="field-title pb-0">
											To:
										</div>
										<div class="field-content p-0">
											<v-text-field
												class="pt-0"
												v-model="msgForm.to"
												:rules="emailRules"
												type="email"
												required
											></v-text-field>
										</div>
									</v-col>

									<v-col
										cols="12"
										md="4"
									>
										<div class="field-title">
											From:
										</div>
										<div class="field-content">
											{{ Auth.userLogin.email }}
										</div>
										
									</v-col>

								</v-row>
								<div class="row">
									<div class="col-6">
										<div class="field-title pb-0">
											Subject:
										</div>
										<div class="field-content p-0">
											<v-text-field
												class="pt-0"
												v-model="msgForm.subject">
											</v-text-field>
										</div>
									</div>
									<div class="col-6">
										<div class="field-title pb-0">
											Template:
										</div>
										<div class="field-content p-0">
											<v-select
												:items="templates"
												v-model="msgForm.template"
												dense
											></v-select>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<editor
											:api-key="APIKeys.tinyMCE"
											cloud-channel="5"
											:disabled=false
											initial-value="Enter data..."
											:inline=false
											v-model="markup"
											:init="{
												height: 500,
												menubar: true,
												plugins: [
													'advlist autolink lists link image charmap print preview anchor',
													'searchreplace visualblocks code fullscreen',
													'insertdatetime media table paste code help wordcount'
												],
												toolbar:
													'undo redo | formatselect | bold italic backcolor | \
													alignleft aligncenter alignright alignjustify | \
													bullist numlist outdent indent | removeformat | help'
											}"
										/>
									</div>
								</div>
							
							</v-form>
						</template>
					</div>
				</div>
			</template>

			<!-- Submit Buttons -->
			<template slot="action-buttons">
				<div class="row justify-end p-0 mt-4">
					<div class="col-3">
						<v-btn 
							width="100%"
							color="warning"
							@click="testButton">
							Reset
						</v-btn>
					</div>
					<div class="col-3">
						<v-btn
							width="100%"
							color="success"
							@click="sendMessage">
							Send
						</v-btn>
					</div>
				</div>
			</template>

		</modalComponent>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

import Editor from '@tinymce/tinymce-vue'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

//Components
import modalComponent from "@/components/popups/modal-component"

export default {
  name: 'newEmailModal',
  components: {
		modalComponent,
		'editor': Editor
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			openModal: false,
			
			//HTML Editor
			markup: "",
      sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
      fontValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
      headerValues: [false, 1, 2, 3, 4, 5],
			isMultiline: true,
			
			//Form Data
			msgForm: {
				to: "ian@datacomnetsol.com",
				from: "",
				subject: null,
				email_type: "Manual",
				template: null,
			},
			templates: [
				"Template One",
				"Big Hero Template",
				"Template Three"
			]

 

    }
  },
  methods: {
    testButton () {
			console.log('this.markup', this.markup)
			console.log('this.msgForm', this.msgForm)


		},
		closeModal(evt) {
			this.openModal = evt
		},
		resetForm() {
			console.log("Reset Form")
			this.markup = ""
			this.msgForm.to = ""
		},
		async sendMessage() {
			console.log("Send Message")
			const message = Object.assign(this.msgForm, {html:this.markup, name:this.Auth.userProfile.full_name})
			let sentEmail = await this.$store.dispatch("SendEmail", message)
			if(sentEmail) {
				var emailObj = Object.assign({}, {
					subject:this.msgForm.subject,
					body:this.markup,
					sender:this.Auth.userProfile.email,
					recipient:this.msgForm.to
				})
				this.$store.dispatch("SaveEmail", emailObj)
			}
			this.resetForm()
			this.openModal = false;
		}

  },
  computed: {
    ...mapState(["Auth", "APIKeys"]),
    ...mapGetters([])

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">

</style>
