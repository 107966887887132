const web = [
  { title: 'Home', icon: 'mdi-home', path: '/'},
  { title: 'Contact', icon: 'mdi-contacts', path: '/contact-us'},
  { title: 'Admin', icon: 'mdi-account-supervisor-circle', path: '/admin'},
]


const main = [
  { title: 'Home', icon: 'mdi-home', path: '/admin' },
  { title: 'Superuser', icon: 'mdi-account-tie', path: '/superuser' },
  { title: 'Merchant', icon: 'mdi-storefront-outline', path: '/merchant' },
]

const superuser = [
  { title: 'Home', icon: 'mdi-home', path: '/admin' },
	{ title: 'Dashboard', icon: 'mdi-gauge', path: '/superuser' },
	{ title: 'Superusers', icon: 'mdi-account-tie', path: '/superuser/superusers' },
	{ title: 'Merchants', icon: 'mdi-storefront-outline', path: '/superuser/merchants' },
	{ title: 'Email Notifications', icon: 'mdi-email', path: '/superuser/email/notifications' },
	{ title: 'Settings', icon: 'mdi-cogs', path: '/superuser/settings' },
]

const merchant = [
  { title: 'Home', icon: 'mdi-home', path: '/admin' },
  { title: 'Dashboard', icon: 'mdi-gauge', path: '/merchant'},
	{ title: 'Employees', icon: 'mdi-account-circle', path: '/merchant/employees'},
	// { title: 'Email Customer', icon: 'mdi-email', path: '/merchant/email'},
	{ title: 'Transactions', icon: 'mdi-swap-horizontal', path: '/merchant/transactions'},
	{ title: 'Post Transaction', icon: 'mdi-credit-card-outline', path: '/merchant/post'},
	{ title: 'Settings', icon: 'mdi-cog', path: '/merchant/settings'}
]



export { web }
export { main }
export { superuser }
export { merchant }

