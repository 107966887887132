<template>
	<div class="flex justify-center">
		<div class="container">
			<div class="headline-3">Superuser Dashboard</div>

			<!-- Select Merchant Accounts -->
			<div class="row justify-center">
				<div class="col-3">
					<v-select 
						:items="GET_MERCHANT_LIST"
						item-text="dba_name"
						item-value="id"
						@change="changeMerchantAccount"
						label="Select Merchant Account"
					 />
				</div>
			</div>
			<div class="row justify-center">
				<div class="col-6 text-center">
					<div class="headline">
						{{ GET_SELECTED_MERCHANT_PROFILE.dba_name || GET_MERCHANT_PROFILE.dba_name }}
					</div>
				</div>
			</div>


			<!-- Main Container Row -->
			<div class="row">
				<!-- Main Content Left Area -->
				<div class="col-12">
	
						<template>
							<div class="row mt-4 justify-center">
								<v-card
									class="p-4 mx-6 my-4"
									max-width="300"
									elevation="5"
								>
									<div class="row mt-2">
										<div class="col-12 p-0 flex justify-center">
											<div class="dashboard-number">
												{{ GET_MERCHANT_LENGTH }}
											</div>
										</div>
									</div>
									<div class="row mt-4">
										<div class="col-12 p-0 text-center">
											<div class="headline-5">
												Merchants
											</div>
										</div>
									</div>
								</v-card>

								<v-card
									class="p-4 mx-6 my-4"
									max-width="300"
									elevation="5"
								>
									<div class="row mt-2">
										<div class="col-12 p-0 flex justify-center">
											<div class="dashboard-number">
												$0.00
											</div>
										</div>
									</div>
									<div class="row mt-4">
										<div class="col-12 p-0 text-center">
											<div class="headline-5">
												Volume
											</div>
										</div>
									</div>
								</v-card>

							</div>
						</template>
						
						<template>
							<v-row v-if="$can('view', 'admin')" class="row mt-5">
								<div class="col-4">
									<v-btn
										@click="testButton">
										Quick Test
									</v-btn>
								</div>
							</v-row>
						</template>
				
			
				</div>
			
			</div>
			<!-- END Main Container Row -->

		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from "axios"
import moment from 'moment'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'
// Event baseUserForm
import { Bus } from "@/js/bus"

export default {
	name: 'superuserDashboard',
	metaInfo: { title: 'Superuser Dashboard' },
  components: {
    DxScrollView
  },
  mixins: [

  ],
  props: {

  },
  data () {
    return {
      // Main Data
			selectedMerchant: null
   
    }
  },
  methods: {
    testButton () {
			console.log("this.GET_TRANSACTION_LIST", this.GET_TRANSACTION_LIST)
			console.log("this.GET_MERCHANT_PROFILE", this.GET_MERCHANT_PROFILE)
			console.log("GET_MERCHANT_LIST", this.GET_MERCHANT_LIST);
			console.log("GET_SELECTED_MERCHANT_PROFILE", this.GET_SELECTED_MERCHANT_PROFILE);
		},

		async changeMerchantAccount(evt) {
			// console.log('changeMerchantAccount evt', evt)
			// Bus.$emit('SetEEMerchant', evt)

			let response = await this.$store.dispatch('setMerchantAccount', {id: evt})

		},
	
		testFS() {
			axios.post("/node/email/test", {name: "Ian"}).then((response) => {
				// console.log('response', response)
			})
		}

  },
  computed: {
    ...mapState(["Merchants", "Auth"]),
    ...mapGetters(["GET_MERCHANT_LENGTH", "GET_MERCHANT_PROFILE", "GET_MERCHANT_LIST", "GET_SELECTED_MERCHANT_PROFILE"])

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">

</style>
