<template>
	<div class="flex justify-center">
		<template>
			<div>

				<v-overlay 
					:value="openModal">
					<v-sheet 
						id="v-sheet"
						light
						:min-width="600"
						elevation="2"
						:width="windowWidth"
						height="80vh"
						class="text-center mx-auto v-sheet">
						
						<div class="row bg-primary">
							<div class="col-9 text-left flex items-center px-5">
								<div class="headline-5 text-white">
									<slot name="title"/>
								</div>
							</div>
							<div class="col-3 h-16 flex items-center justify-end pr-5">
								<v-btn
									icon
									color="white"
									@click="closeModal"
								>
									<v-icon size="60">mdi-close-circle-outline</v-icon>
								</v-btn>
							</div>
						</div>

						<div class="row">
							<div 	
								class="p-4 w-full"
								width="100%">
								<DxScrollView
									show-scrollbar="always"
									class="customHeight"
									width="100%">
									
									<slot name="body"/>
									
								</DxScrollView>
							</div>
						</div>


						<div class="row border-t-4 border-gray-400">
							<slot name="action-buttons" />
						</div>
							

					</v-sheet>
					
				</v-overlay>
				
			</div>
		</template>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'

export default {
  name: 'modalComponent',
  components: {
    DxScrollView
  },
  mixins: [

  ],
  props: {
    openModal: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
			// Main Data
			windowWidth: 0,
			

    }
  },
  methods: {
    testButton() {
			console.log("Overlay clicked")
			var divHeight = document.getElementById('v-sheet').clientHeight
			console.log("divHeight", divHeight)
    },
    closeModal() {
      this.$emit('closeModal', false)
		},
		onClickOutside() {
			this.$emit('closeModal', false)
		},
		resizeWindow() {
			this.windowWidth = window.innerWidth *.8
		},


  },
  computed: {
    ...mapState([]),
		...mapGetters([]),


	},
  mounted () {
		this.windowWidth = window.innerWidth *.8
		window.addEventListener('resize', this.resizeWindow)
	},
	beforeDestroy() {
  // Unregister the event listener before destroying this Vue instance
  window.removeEventListener('resize', this.resizeWindow)
	}
}
</script>

<style scoped lang="scss">
.customHeight {
	height: calc(71vh - 80px);
}
</style>
