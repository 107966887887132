
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export const APINotifications = { // Snackbar Notifications
	namespace: true,
	state: {

		updateNotification: new Array(),
		loginErrorData: new Array(),
		invErrorData: new Array(),
		superuserErrorData: new Array(),
		merchantErrorData: new Array(),
		userErrorData: new Array(),
		employeeErrorData: new Array(),
		vendorErrorData: new Array(),
		loginErrorHandle: false,
		invErrorHandle: false,
		superuserErrorHandle: false,
		merchantErrorHandle: false,
		userErrorHandle: false,
		employeeErrorHandle: false,
		
	},
	mutations: {
		//Main Subscriber for Toast's and Notifications
		UPDATE_NOTIFICATIONS(state, payload) {
			state.updateNotification = payload;
			// console.log("updateNotifications Message Payload in store.js:", payload);
		},
		//Login Errors
		SET_LOGIN_ERRORS(state, payload) {
			state.loginErrorHandle = true;
			state.loginErrorData = Object.entries(payload);
			// console.log("state.loginErrorData", state.loginErrorData);
		},
		SET_FORGOT_PW_ERRORS(state, payload) {
			// console.log("payload", payload);
			var pushPayload = ["username", [payload]];
			state.loginErrorHandle = true;
			state.loginErrorData.push(pushPayload);
			// console.log("state.loginErrorData", state.loginErrorData);
		},
		//Error Handing for Datacom
		SET_SUPERUSER_ERRORS(state, payload) {
			state.superuserErrorHandle = true;
			state.superuserErrorData = Object.entries(payload);
		},

		//Error Handing for Merchants
		SET_MERCHANT_ERRORS(state, payload) {
			state.merchantErrorHandle = true;
			state.merchantErrorData = Object.entries(payload);
		},
		//Error Handing for Vendors
		SET_VENDOR_ERRORS(state, payload) {
			state.vendorErrorHandle = true;
			state.vendorErrorData = Object.entries(payload);
		},
		//Error Handing for User and Employees
		SET_USER_ERRORS(state, payload) {
			state.userErrorHandle = true;
			state.userErrorData = Object.entries(payload);
		},
		SET_CUSTOMER_ERRORS(state, payload) {
			state.customerErrorHandle = true;
			state.customerErrorData = Object.entries(payload);
		},
		SET_EMPLOYEE_ERRORS(state, payload) {
			state.userErrorHandle = true;
			state.employeeErrorData = Object.entries(payload);
		},

		//Reset All Errors
		RESET_ERRORS(state, payload) {
			state.updateNotification = null
			state.loginErrorData = new Array()
			state.invErrorData = new Array()
			state.superuserErrorData = new Array()
			state.merchantErrorData = new Array()
			state.userErrorData = new Array()
			state.employeeErrorData = new Array()
			state.vendorErrorData = new Array()
			state.loginErrorHandle = false
			state.invErrorHandle = false
			state.superuserErrorHandle = false
			state.merchantErrorHandle = false
			state.userErrorHandle = false
			state.employeeErrorHandle = false
		}
	},
	actions: { // Snack Notifications
		updateNotification({ dispatch, commit, rootState }, payload) {
			// console.log('updateNotification payload', payload)
			if(payload == undefined) {
				return
			}
			if (payload.status === 200) {
				// console.log("The updateNotification action had a status of 200 OK:", payload);
				commit("UPDATE_NOTIFICATIONS", {color: 'success', msg: `Successful operation from ${payload.type}`});
			}
			else if (payload.status === 201) {
				// console.log("The updateNotification action had a status of 201 Created:", payload);
				commit("UPDATE_NOTIFICATIONS", {color: 'success', msg: `The ${payload.type} was successfully created.`});
			}
			else if (payload.status === 202) {
				// console.log("The updateNotification action had a status of 201 Updated:", payload);
				commit("UPDATE_NOTIFICATIONS", {color: 'success', msg: `The ${payload.type} was successfully Updated.`});
			}
			else if (payload.status === 400) {
				// console.log("The updateNotification action had a status of 400 Bad Request:", payload);
				var titleMsg 
				var errorList = ""
				var errorMessage = payload.data.errorName || payload.data.ssl_result_message || payload.data.detail
				if( Object.keys(payload.data)[0] === 'non_field_errors') {
					titleMsg = 'Non Form Error(s)'
					let arrayList = Object.values(payload.data)[0]


					// for(const key in arrayList) {
					// 	// console.log('key', key)
					// 	// console.log('val', arrayList[key])
					// 	errorList += arrayList[key] + "\n"
					// 	// console.log('errorList', errorList)
					// }

					errorList = arrayList
					errorMessage? errorList += errorMessage + "\n": undefined

				}	else {
						titleMsg = 'Form Error(s)' 
						let arrayList = Object.values(payload.data)[0]
						// for(const key in arrayList) {
						// 	// console.log('key', key)
						// 	// console.log('arrayList', arrayList)

						// 	errorList += arrayList[key] + "\n"
							
						// 	// console.log('errorList', errorList)
						// }

						errorList = arrayList
						errorMessage? errorList += errorMessage + "\n": undefined
				}
				
				dispatch(
					"notificationPopup",
					{title: titleMsg, body: errorList}
				);
				//Superuser Errors
				if (payload.type === "Create New Datacom") {
					commit("SET_SUPERUSER_ERRORS", payload.data);
				}
				//Merchant Errors
				if (payload.type === "Create New Company") {
					commit("SET_MERCHANT_ERRORS", payload.data);
				}
				//Vendor Errors
				if (payload.type === "Create New Vendor") {
					commit("SET_VENDOR_ERRORS", payload.data);
				}
				//User Errors
				if (payload.type === "Create New User") {
					commit("SET_USER_ERRORS", payload.data);
				} 
				//Employee Errors
				if (payload.type === "Create New Employee") {
					commit("SET_EMPLOYEE_ERRORS", payload.data);
				}
				//Login Errors
				if (payload.type === "Login Unsuccessful") {
					commit("SET_LOGIN_ERRORS", payload.data);
				}

			}
			else if (payload.status === 401) {
				// console.log("The updateNotification action had a status of 401 unauthorized:", payload);
				commit(
					"UPDATE_NOTIFICATIONS",
					{color: 'warning', msg:`You are unauthorized to perform this action. ${payload.type} Please contact your administrator`}
				);
			}

			//Login Errors
			//Merchant Errors
			//User Errors
			//Employee Errors
			else if (payload.status === 403) {
				// console.log("The updateNotification action had a status of 403 forbidden:", payload);
				commit(
					"UPDATE_NOTIFICATIONS",
					{color: 'warning', msg:`The request to ${payload.type} is a forbidden. Please contact your administrator.`}
				);
			}
			//Login Errors
			//Merchant Errors
			//User Errors
			//Employee Errors
			else if (payload.status === 404) {
				// console.log("The updateNotification action had a status of 404 Not Found:", payload);
				commit("UPDATE_NOTIFICATIONS", {color: 'info', msg:`The request to ${payload.type} was not found. Please try differently.`});
			}
			else if (payload.status === 429) {
				// console.log("The updateNotification action had a status of 429 Throttle:", payload);
				commit("UPDATE_NOTIFICATIONS", {color: 'info', msg:` ${payload.data.detail}`}); // Correct
			}
			else if (payload.status === 500) {
				// console.log(
				// 	"The updateNotification action had a status of 500 Internal Server Error: payload.message",
				// 	payload.message
				// );
				commit(
					"UPDATE_NOTIFICATIONS",
					{color: 'error', msg:"There was an internal server error. Please try again later or contact support."}
				);
			}
			//Login Errors
			//Merchant Errors
			//User Errors
			//Employee Errors

			else if (payload.status === 502) {
				// console.log("The updateNotification action had a status of 500 Bad Gateway Request:", payload);
				commit(
					"UPDATE_NOTIFICATIONS",
					{color: 'warning', msg:`The server address to: ${payload.type} is not working. Please contact support.`}
				);
			}
			else if (payload.status === 503) {
				// console.log("The updateNotification action had a status of 500 Service Unavailable:", payload);
				commit(
					"UPDATE_NOTIFICATIONS",
					{color: 'info', msg:`The service: ${payload.type} is offline or unavailable. Please try again later.`}
				);
			}
			else if (payload.status === 504) {
				// console.log("The updateNotification action had a status of 500 Gateway Timeout:", payload);
				commit(
					"UPDATE_NOTIFICATIONS",
					{color: 'info', msg:`The server gateway to:  ${payload.type} is not responding. Please try again later.`}
				);
			}
			else if (payload.status === 2000) {
				// console.log("Custom error Handled. User Not logged in trying to execute a Vuex action:", payload);
				commit("UPDATE_NOTIFICATIONS", {color: 'info', msg:`You must be logged in:  ${payload.type}.`});
			}
		}
	},
	getters: {
		GET_SUPERUSER_ERRORS_LIST(state) {
			return state.superuserErrorData;
		},
		GET_SUPERUSER_ERRORS_LIST_LENGTH(state) {
			return state.superuserErrorData.length;
		},
		GET_SUPERUSER_ERROR_HANDLE(state) {
			return state.superuserErrorHandle;
		},
		GET_MERCHANT_ERRORS_LIST(state) {
			return state.merchantErrorData;
		},
		GET_MERCHANT_ERROR_HANDLE(state) {
			return state.merchantErrorHandle;
		},
		GET_MERCHANT_ERRORS_LIST_LENGTH(state) {
			return state.merchantErrorData.length;
		},
		GET_VENDOR_ERRORS_LIST(state) {
			return state.vendorErrorData;
		},
		GET_VENDOR_ERRORS_LIST_LENGTH(state) {
			return state.vendorErrorData.length;
		},
		GET_VENDOR_ERROR_HANDLE(state) {
			return state.vendorErrorHandle;
		},
		GET_CUSTOMER_ERRORS_LIST(state) {
			return state.customerErrorData;
		},
		GET_CUSTOMER_ERRORS_LIST_LENGTH(state) {
			return state.customerErrorData.length;
		},
		GET_CUSTOMER_ERROR_HANDLE(state) {
			return state.customerErrorHandle;
		},
		GET_USER_ERRORS_LIST(state) {
			return state.userErrorData;
		},
		GET_USER_ERRORS_LIST_LENGTH(state) {
			return state.userErrorData.length;
		},
		GET_USER_ERROR_HANDLE(state) {
			return state.userErrorHandle;
		},
		GET_EMPLOYEE_ERRORS_LIST(state) {
			return state.employeeErrorData;
		},
		GET_EMPLOYEE_ERRORS_LIST_LENGTH(state) {
			return state.employeeErrorData.length;
		},
		GET_EMPLOYEE_ERROR_HANDLE(state) {
			return state.employeeErrorHandle;
		},
		
	}
};
