<template>
	<div>
		<!-- PreLoader -->
		<div>Loader goes here</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from 'vuex';

//Components


export default {
	name: "loadingStateComponent",
	mixins: [],
	components: {
	},
	props: {

	},
	data() {
		return {
			//Component Data

			//Main Data


		};
	},
	methods: {
		testingMethod(e) {

		}
	},
	computed: {

	},
	created() {

	},
	mounted() {}
};
</script>

<style scoped lang="less">

</style>
