<template>
	<div class="flex justify-center">
		<div class="container mt-4">
				<!-- Main Container Row -->
				<div class="row">
					<!-- Main Content Area -->
					<div class="col-12 px-5 py-0">
						<v-tabs>
							<v-tab>Email History</v-tab>
							<v-tab>Email Templates</v-tab>
							<!-- Tab One - Database -->
							<v-tab-item>
								<DxScrollView
									:scroll-by-content="true"
									:scroll-by-thumb="true"
									show-scrollbar="onHover"
									height="90vh"
								>
			
									<template>
										<v-card>
											<v-card-title class="mb-1 bg-primary text-white">
												<div class="row">
													<div class="col-6">
														<div class="title">
															Emails
														</div>
													</div>
													<div class="col-6 flex items-center">
														<v-text-field
															class="m-0 p-0"
															color="teal"
															dark
															v-model="search"
															append-icon="mdi-magnify"
															label="Search"
															single-line
															:single-select="singleSelect"
															hide-details
														></v-text-field>
													</div>
												</div>
											</v-card-title>
											<div v-if="selected.length === 1" class="row justify-end">
												<div class="col-2">
													<v-btn small class="m-2" fab dark color="accent" @click="deleteItem">
															<v-icon dark>mdi-trash-can-outline</v-icon>
														</v-btn>
												</div>
											</div>
											<v-data-table
												v-model="selected"
												:headers="headers"
												:items="GET_EMAIL_LIST"
												:search="search"
												item-key="title"
												class="elevation-1"
												@click:row="tableClick"
											>
												<template v-slot:top>
													<div class="row justify-end">
														<div class="col-2">
															<v-btn 
																@click="openNewEmailModal"
																class="mx-2"
																dark 
																color="accent">
																New Email
																<v-icon dark>mdi-plus-thick</v-icon>
															</v-btn>

															<!-- New Email Modal -->
															<newEmailModal
																ref="newEmailModalRef">
															</newEmailModal>

														</div>
													</div>
													
												</template>
											</v-data-table>

											<!-- View Email Modal -->

											<viewEmailModal
												ref="viewEmailModalRef">
											</viewEmailModal>


										</v-card>
									</template>
								</DxScrollView>
							</v-tab-item>
							<!-- Tab Two - Templates -->
							<v-tab-item>
								
								<emailTemplatesComponent>
								</emailTemplatesComponent>

							</v-tab-item>
						</v-tabs>
						
					</div>
					<!-- END Main Content Area -->

				</div>
				<!-- END Main Container Row -->
		</div>
	
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

//Components
import newEmailModal from "@/components/email/new-email"
import viewEmailModal from "@/components/email/view-email"
import emailTemplatesComponent from "@/pages/superuser-portal/components/email-templates"

export default {
	name: 'emailNotifications',
	metaInfo: { title: 'Site Email Notifications' },
  components: {
		newEmailModal,
		viewEmailModal,
		emailTemplatesComponent,
		DxScrollView
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			
      // Vuetify DataTable
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
				{ text: 'To', value: 'to' },
				{ text: 'Subject', value: 'subject' },
        { text: 'Type', value: 'type'},
        { text: 'Status', value: 'status' },
      ],
     
			
      // Form Data
      parentForm: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        fax: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip_code: null,
        notes: null,
        students: ['Beth Jacobs', 'Eric Little', 'Ryanne Conners']
      }

    }
  },
  methods: {
    testButton () {
			// console.log("testButton")
			// console.log("this.markup, ", this.markup)
		},
		openNewEmailModal() {
			this.$refs.newEmailModalRef.openModal = true
		},
		tableClick(evt) {
			// console.log('table click evt', evt)
			this.$refs.viewEmailModalRef.openModal = true
			//I need to pass in the row data to open the correct email from Vuex
		},

	

  },
  computed: {
    ...mapState([]),
    ...mapGetters(["GET_EMAIL_LIST"])

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">
.offset-email-buttons {
	display: block;
	position: absolute;
	top: 50px;
}
</style>
