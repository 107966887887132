<template>
  <section :id="id">
    <div no-gutters>
      <div cols="12">
        <component
          :is="`section-${section}`"
          v-for="section in sections"
          :key="section"
        />
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'BaseWebView',

    props: {
      id: {
        type: String,
        default: 'view',
      },
    },

		data: () => ({ sections: [] }),

  }
</script>
