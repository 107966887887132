<template>
  <base-section id="locked">
    <base-heading title="Account Locked" />

    <base-subheading
      class="primary--text"
      title="Your account is not currently active."
    />

    <base-subheading
      space="8"
      title="Please call for more information. 1-855-699-3282"
    />

    <base-img
      :src="require('@/assets/static/RedDangerIcon150x150.png')"
      class="mx-auto mb-12"
      contain
      height="350"
      width="250"
    /> 

    <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        Get me Out of Here
      </base-btn>
    </div>
  </base-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'lockedAccount',
	 provide: {
		heading: { align: 'center' },
	},
  components: {
  },
  mixins: [

  ],
  data () {
    return {
			// Main Data


    }
  },
  methods: {
    testButton () {

		},



  },
  computed: {
    ...mapState([]),
    ...mapGetters([])

  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.login-form {
	margin-top: 15vh;
}
</style>
