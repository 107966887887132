<template>
	<div class="row justify-center">

		<v-form
			ref="validateFormRef"
			v-model="validCardholderForm">
			<div class="row">
				<div class="col-6">
					<div class="row">
						<div class="field-title">
							First Name
						</div>
					</div>
					<div class="row">
						<div class="field-content">
							<v-text-field 
							color="primary lighten-2"
								outlined
								v-model="form.first_name"
								:rules="nameRules"
								required>
							</v-text-field>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div class="row">
						<div class="field-title">
							Last Name
						</div>
					</div>
					<div class="row">
						<div class="field-content">
							<v-text-field 
								outlined
								v-model="form.last_name"
								:rules="nameRules"
								required>
							</v-text-field>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-9">
					<div class="row">
						<div class="field-title">
							Address
						</div>
					</div>
					<div class="row">
						<div class="field-content">
							<v-text-field
								color="primary lighten-3" 
								outlined
								v-model="form.billing_address"
								:rules="[rules.required]">
							</v-text-field>
						</div>
					</div>
				</div>
				<div class="col-3">
					<div class="row">
						<div class="field-title">
							Apt / Unit / etc.
						</div>
					</div>
					<div class="row">
						<div class="field-content">
							<v-text-field 
								outlined
								v-model="form.billing_address2">
							</v-text-field>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-4">
					<div class="row">
						<div class="field-title">
							City
						</div>
					</div>
					<div class="row">
						<div class="field-content">
							<v-text-field 
								outlined
								v-model="form.billing_city"
								:rules="[rules.required]">
							</v-text-field>
						</div>
					</div>
				</div>
				<div class="col-4">
					<div class="row">
						<div class="field-title">
							State
						</div>
					</div>
					<div class="row">
						<div class="field-content">
							<v-select
								v-model="form.billing_state"
								:items="states"
								item-text="state"
								item-value="abbr"
								persistent-hint
								label="State *"
							></v-select>

							<!-- <v-text-field 
								outlined
								v-model="form.billing_state"
								:rules="stateRules"
								v-mask="'AA'">
							</v-text-field> -->
						</div>
					</div>
				</div>
				<div class="col-4">
					<div class="row">
						<div class="field-title">
							Zip Code
						</div>
					</div>
					<div class="row">
						<div class="field-content">
							<v-text-field 
								outlined
								v-model="form.billing_zip_code"
								:rules="[rules.required, rules.zipRule]"
								v-mask="'#####'">
							</v-text-field>
						</div>
					</div>
				</div>
			</div>
		</v-form>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'
import { stateNames } from "@/js/states.js"

export default {
  name: 'cardholderDetailsComponent',
  components: {
  },
  mixins: [
    FormMixins
  ],
  props: {
		form: {
			type: Object, 
			required: true
		}
  },
  data () {
    return {
			// Main Data
			validCardholderForm: true,
			// Form Data
      states: stateNames
		
    }
  },
  methods: {
    testButton () {

		},
		validate() {
			return this.$refs.validateFormRef.validate()
		}


  },
  computed: {
    ...mapState([]),
		...mapGetters([]),
		

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">

</style>
