<template>
	<div class="flex justify-center">
		<div class="container login-form">

			<!-- Set New PW Form -->
			<v-row class="justify-center" v-if="$route.query.address">
				<v-col cols="9" md="6" lg="4">
					<v-card
						min-width="300px"
						width="100%">
						<v-card-title class="bg-primary text-white">
							Set New Password
						</v-card-title>
						<v-form
							ref="setNewPWFormRef"
							v-model="validSetNewPWForm">

							<v-row class="justify-center">
								<div class="col-10">
									<v-text-field
										label="Email *"
										:rules="[rules.required]"
										outlined
										v-model="newForm.email"
									></v-text-field>
								</div>
							</v-row>


							<!-- Error Handling - NEED TO FINISH-->
							<!-- <v-row 
								v-for="error in errorData" 
								:key="error.id">	
								<article
									v-if="error[0] === 'email'"
									class="has-background-white margin-top-half"
									:class="`message ${errorHandle ? 'is-danger' : 'is-success'}`">
									<div 
										class="message-body">
										{{ error[1][0] }}
									</div>
								</article>
							</v-row> -->

							<v-row class="justify-center mt-4">
								<div class="col-10">
									<v-text-field
										label="New Password *"
										outlined
										type="password"
										:rules="passwordRules"
										v-model="newForm.new_password"
									></v-text-field>
								</div>
							</v-row>
							<v-row class="justify-center">
								<div class="col-10">
									<v-text-field
										outlined
										v-model="confirmPassword"
										:rules="passwordRules"
										label="Confirm New Password *"
										type="password"
									></v-text-field>
								</div>
							</v-row>
						</v-form>
						

						<!-- Alert -->
						<div v-if="!newForm.id" class="row">
							<div class="col-12">
								<v-alert
									v-if="compareSetNewPassword"
									text
									type="error"
									icon="mdi-information"
									>
									Passwords do not match
								</v-alert>
							</div>
						</div>

						<v-card-text>
							<v-form
								ref="pwResetFormRef"
								v-model="validEmail">
								<v-row class="justify-center">
									<div class="col-11">
										<v-btn
											@click="setNewPassword"
											color="secondary"
											width="100%">
											Set Password
										</v-btn>
									</div>
								</v-row>
							</v-form>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<!-- Initial Password Request -->
			<v-row class="justify-center" v-else>
				<v-col cols="9" md="6" lg="4">
					<v-card
						min-width="300px"
						width="100%">
						<v-card-title class="bg-primary text-white">
							Forgot Password
						</v-card-title>
						<v-form
								ref="pwResetFormRef"
								v-model="validEmail">

							<v-row class="justify-center">
								<div class="col-10">
									<v-text-field
										label="Email"
										:rules="[rules.required]"
										outlined
										v-model="loginForm.email"
									></v-text-field>
								</div>
							</v-row>

							<v-card-text>
								<v-row class="justify-center">
									<div class="col-11">
										<v-btn
											@click="resetPassword"
											color="secondary"
											width="100%">
											Reset Password
										</v-btn>
									</div>
								</v-row>
								<v-row class="">
									<div class="col-6">
										<!-- <v-btn
											@click="testButton">
											Test
										</v-btn> -->
									</div>

									<div class="col-6 flex justify-end mt-4">
										<v-btn
											text
											outlined
											color="success"
											small
											:to="{name:'Login'}">
											Login
										</v-btn>

									</div>
								</v-row>
						
							</v-card-text>
						</v-form>
					</v-card>
				</v-col>
			</v-row>

			<!-- Password Helper Component -->
			<v-row class="mt-10 justify-center" v-show="accountSettings.editProfile">
				<v-row class="full-width justify-center" v-if="accountSettings.showPasswordReset">
					<v-col cols="10" class="">
						<table class="password-requirements-table align-self-center">
						
							<tbody>
								<tr>
									<td>
										<v-row>
											<v-col cols="1">
												<v-icon
													:size="20"
													:color="minOneLetter">
													mdi-check-bold
												</v-icon>
											</v-col>	
											<v-col cols="10">
												Minimum of One Letter: a, b c, d, e
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="1">
												<v-icon
													:size="20"
													:color="minOneNumber">
													mdi-check-bold
												</v-icon>
											</v-col>	
											<v-col cols="10">
												Minimum of One Number: 1234567890
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="1">
												<v-icon
													:size="20"
													:color="minOneCharacter">
													mdi-check-bold
												</v-icon>
											</v-col>	
											<v-col cols="10">
												Minimum of One Special Character: #@*^&!
											</v-col>
										</v-row>
									</td>
									<td>
										<v-row>
											<v-col cols="1">
												<v-icon
													:size="20"
													:color="minSixChars">
													mdi-check-bold
												</v-icon>
											</v-col>	
											<v-col cols="10">
												Minimum 6 characters
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="1">
												<v-icon
													:size="20"
													:color="cantUseName">
													mdi-check-bold
												</v-icon>
											</v-col>	
											<v-col cols="10">
												Cannot use your name in password
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="1">
												<v-icon
													:size="20"
													:color="cantUseCommon">
													mdi-check-bold
												</v-icon>
											</v-col>	
											<v-col cols="10">
												No common words such as 'password'
											</v-col>
										</v-row>
									</td>
								</tr>
							</tbody>
						</table>
					</v-col>
				</v-row>
			</v-row>

		</div>
	</div>
</template>

<script>
"use-strict";
import { mapGetters, mapState } from 'vuex'
import { FormMixins } from "@/mixins/form-mixins"

// components

export default {
  name: 'passwordReset',
	metaInfo: { title: 'Set Paassword' },
  components: {
  },
  mixins: [
		FormMixins
  ],
  data () {
    return {
			// Main Data
			validEmail: false,
			validSetNewPWForm: false,
			confirmPassword: '',
			passwordMessage: "",

			loginForm: {
				email: null
			},
			newForm: {
				email: null,
				password: null,
				new_password: null,
			},
			accountSettings: {
				showPasswordReset: true,
				editProfile: true,
				hideSaveItem: true,
			},


    }
  },
  methods: {
    testButton () {
			console.log('this.validEmail', this.validEmail)
			console.log('this.loginForm', this.loginForm)
		},
		async resetPassword() { // Send email with link to change Password
			// console.log("resetPassword this.validEmail",this.validEmail)
			this.$refs.pwResetFormRef.validate()
			if(this.validEmail) {
				// Verify User Acocunt on the backend
				let validateUserEmail = await this.$store.dispatch('verifyUserAccount', {email: this.loginForm.email}); // True or false response
				// console.log("validateUserEmail", validateUserEmail);

				this.$store.dispatch('notificationPopup', {title: "Password Reset Link Sent", body: "If your account is found on file"});
			} 
		},
		async setNewPassword() { // Link Clicked. Process New Password
			this.$refs.setNewPWFormRef.validate()
			// console.log("setNewPassword this.validSetNewPWForm",this.validSetNewPWForm)

			if(this.validSetNewPWForm) {
				if(this.confirmPassword == this.newForm.new_password) {
					
					var newObj = {
						email: this.newForm.email,
						new_password: this.newForm.new_password,
						confirm_password: this.confirmPassword
					}
					// console.log("newObj", newObj);
					
					let password = await this.$store.dispatch('setNewPassword', newObj);
					// console.log('password', password)
					if(password) {
						this.$store.dispatch('notificationPopup', {title: "Success", body: "Password has been changed"});
						this.$refs.setNewPWFormRef.reset()
						this.$router.push({name: "Login"})
					} else {
					this.$store.dispatch('notificationPopup', {title: "Error", body: "There was an error processing your request"});
				}
				}
			} 
		}

  },
  computed: {
    ...mapState(['Auth']),
    ...mapGetters(['IS_AUTHENTICATED', "GET_USER_PROFILE"]),
		errorData() {
			return this.GET_USER_ERRORS_LIST
		},
		errorHandle() {
			return this.GET_USER_ERROR_HANDLE
		},
		// computedPasswords: {
		// 	get() {
		// 		console.log("this.passwordMessage", this.passwordMessage);
		// 		if (this.userForm.password != this.userForm.verify_pw) {
		// 			return "The passwords do not match";
		// 		} else {
		// 			return;
		// 		}
		// 	},
		// 	set(newVal, oldVal) {
		// 		console.log('newVal', newVal);
		// 		console.log('oldVal', oldVal);

		// 		this.passwordMessage = "New Message";
		// 	},
		// },
		minOneLetter() {
			// console.log('minOneLetter');
			var re = /[a-zA-Z]/;
			if(this.newForm.new_password) {
				if(this.newForm.new_password.match(re)) {
					return 'green';
				}
			}
			return 'red';
		},
		minOneNumber() {
			// console.log('minOneNumber');
			var re = /[0-9]/;
			if(this.newForm.new_password) {
				if(this.newForm.new_password.match(re)) {
					return 'green';
				}
			}
			return 'red';
		},
		minOneCharacter() {
			// console.log('minOneCharacter');
			var re = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			if(this.newForm.new_password) {
				if(this.newForm.new_password.match(re)) {
					return 'green';
				}
			}
			return 'red';
		},
		minSixChars() {
			// console.log('minSixChars');
			var re = /.{6,}$/;
			if(this.newForm.new_password) {
				if(this.newForm.new_password.match(re)) {
					return 'green';
				}
			}
			return 'red';
		},
		cantUseName() {
			// console.log('cantUseName');
			if(this.GET_USER_PROFILE.first_name) {
				var firstname = this.GET_USER_PROFILE.first_name.toLowerCase();
			}
			if(this.GET_USER_PROFILE.last_name) {
				var lastname = this.GET_USER_PROFILE.last_name.toLowerCase();
			}
			if(this.GET_USER_PROFILE.email) {
				var email = this.GET_USER_PROFILE.email.toLowerCase();
			}
			var words = [firstname, lastname, email];
			if(this.newForm.new_password) {
				var password = this.newForm.new_password.toLowerCase();
				for(let key in words) {
					if(password.includes(words[key])) {
						return 'red'
					} 
				}
			}
			return 'green';
		},
		cantUseCommon() {
			// console.log('cantUseCommon');
			let name = this.$route.query.address.split("@")[0]
			var words = ['password', '12345', '11111', '00000', '54321', '1111', '0000', this.$route.query.address, name];

			if(this.newForm.new_password) {
				var password = this.newForm.new_password.toLowerCase();
				for(let key in words) {
					if(password.includes(words[key])) {
						return 'red'
					} 
				}
			}
			return 'green';
		},
		isValidEmail() {
			// console.log('cantUseCommon');
			var re = /([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/;
			if(this.userForm.email) {
				if(this.userForm.email.match(re)) {
					return 'green';
				}
			}
			return 'red';
		}

  },
  mounted () {
		// console.log("this.$route.params", this.$route.params)
		if(this.$route.params.email) {
			this.loginForm.email = this.$route.params.email
		}
		
		// console.log("this.$route.query", this.$route.query)
		if(this.$route.query.address != undefined) {
			this.newForm.email = this.$route.query.address
		}	
		
  }
}
</script>

<style lang="scss" scoped>
.login-form {
	margin-top: 15vh;
}

.password-requirements-table {
	border-width:2px;
	border-color: rgb(141, 140, 140);
	margin: 0px auto;
	width: 80%;
	background: rgb(231, 231, 231);
	
	p {
		font-family: OpenSans-Bold;

	}
	tbody {
		tr {
			td {
				ul {
					li {
						list-style: disc inside;
					}
				}
			}
		}
	}
}
</style>
