var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center"},[_c('modalComponent',{attrs:{"openModal":_vm.openModal},on:{"closeModal":_vm.closeModal}},[_c('template',{slot:"title"},[_c('div',[_vm._v(" New Email ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"field-title pb-0"},[_vm._v(" To: ")]),_c('div',{staticClass:"field-content p-0"},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":_vm.emailRules,"type":"email","required":""},model:{value:(_vm.msgForm.to),callback:function ($$v) {_vm.$set(_vm.msgForm, "to", $$v)},expression:"msgForm.to"}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"field-title"},[_vm._v(" From: ")]),_c('div',{staticClass:"field-content"},[_vm._v(" "+_vm._s(_vm.Auth.userLogin.email)+" ")])])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"field-title pb-0"},[_vm._v(" Subject: ")]),_c('div',{staticClass:"field-content p-0"},[_c('v-text-field',{staticClass:"pt-0",model:{value:(_vm.msgForm.subject),callback:function ($$v) {_vm.$set(_vm.msgForm, "subject", $$v)},expression:"msgForm.subject"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"field-title pb-0"},[_vm._v(" Template: ")]),_c('div',{staticClass:"field-content p-0"},[_c('v-select',{attrs:{"items":_vm.templates,"dense":""},model:{value:(_vm.msgForm.template),callback:function ($$v) {_vm.$set(_vm.msgForm, "template", $$v)},expression:"msgForm.template"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('editor',{attrs:{"api-key":_vm.APIKeys.tinyMCE,"cloud-channel":"5","disabled":false,"initial-value":"Enter data...","inline":false,"init":{
											height: 500,
											menubar: true,
											plugins: [
												'advlist autolink lists link image charmap print preview anchor',
												'searchreplace visualblocks code fullscreen',
												'insertdatetime media table paste code help wordcount'
											],
											toolbar:
												'undo redo | formatselect | bold italic backcolor | \
												alignleft aligncenter alignright alignjustify | \
												bullist numlist outdent indent | removeformat | help'
										}},model:{value:(_vm.markup),callback:function ($$v) {_vm.markup=$$v},expression:"markup"}})],1)])],1)]],2)])]),_c('template',{slot:"action-buttons"},[_c('div',{staticClass:"row justify-end p-0 mt-4"},[_c('div',{staticClass:"col-3"},[_c('v-btn',{attrs:{"width":"100%","color":"warning"},on:{"click":_vm.testButton}},[_vm._v(" Reset ")])],1),_c('div',{staticClass:"col-3"},[_c('v-btn',{attrs:{"width":"100%","color":"success"},on:{"click":_vm.sendMessage}},[_vm._v(" Send ")])],1)])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }