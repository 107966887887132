<template>
	<div class="flex justify-center">
		<div class="container login-form">
			<div class="row justify-center">
				<v-col cols="9" md="6" lg="4">
					<v-card
						min-width="300px"
						width="100%">
						<v-card-title class="bg-primary text-white">
							Login
						</v-card-title>
						<v-form
							ref="loginFormRef"
							v-model="validLoginForm">
							<div class="row justify-center">
								<div class="col-10">
									<v-text-field
										ref="emailFieldRef"
										label="Email"
										outlined
										:rules="nameRules"
										v-model="loginForm.username"
									></v-text-field>
								</div>

							</div>
							<div class="row justify-center">
								<div class="col-10">
									<v-text-field
										label="Password"
										outlined
										:type="showPW ? 'text' : 'password'"
										:append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
										@click:append="showPW = !showPW"
										:rules="passwordRules"
										v-model="loginForm.password"
									></v-text-field>
								</div>
							</div>
						</v-form>
						
						<v-card-text>
							<div class="row justify-center">
								<div class="col-11">
									<v-btn
										ref="submitBtnRef"
										@click="loginHere"
										color="success"
										width="100%">
										Login here
									</v-btn>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<!-- <v-btn
										@click="testButton">
										Test
									</v-btn> -->
								</div>
								<div class="col-6 flex justify-end mt-4">
									<v-btn
										text
										color="secondary"
										small
										outlined
										:to="{name:'Password Reset', params: {email: loginForm.username}}">
										forgot password
									</v-btn>
								</div>
							</div>

						</v-card-text>
					</v-card>
				</v-col>

			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

//Mixins
import { FormMixins } from "@/mixins/form-mixins"

export default {
  name: 'loginView',
  components: {
  },
  mixins: [
		FormMixins
  ],
  data () {
    return {
			// Main Data
			validLoginForm: false,
			showPW: false,
      loginForm: {
        username: null,
        password: null
      }

    }
  },
  methods: {
    testButton () {
      // console.log('this.GET_USER_PROFILE', this.GET_USER_PROFILE)
    },
    loginHere () {
			// console.log('loginHere')
			let valid = this.$refs.loginFormRef.validate()
			
			// console.log('validLoginForm', this.validLoginForm)
			if(valid) {
				this.$store.dispatch('login', this.loginForm)
			}
		},
		pressEnter(evt) {
			// console.log('evt', evt)
			// console.log('this.$refs.submitBtnRef', this.$refs.submitBtnRef)
			 if (evt.key === 'Enter') {
				// code for enter
				this.$refs.submitBtnRef.$el.click()
    	}
		}

  },
  computed: {
    ...mapState(['Auth']),
    ...mapGetters(['IS_AUTHENTICATED', 'GET_USER_PROFILE'])

  },
  mounted () {
		this.$nextTick(() => this.$refs.emailFieldRef.$el.focus())
		window.addEventListener('keypress', this.pressEnter)
	},
	beforeDestroy() {
		// Unregister the event listener before destroying this Vue instance
		window.removeEventListener('keypress', this.pressEnter)
	}
}
</script>
<style lang="scss" scoped>
.login-form {
	margin-top: 15vh;
}
</style>
