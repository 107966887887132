// console.log("Loaded Universal Mixin");
//This is not a Vuex Template but a <script> template

export const UniversalMixin = {
  data() {
    return {

    }
  },
  methods: {
		setMerchantIDforPOST(form) {
			return new Promise(async (resolve, reject) => {
				var newForm = {};
				if (form != undefined) {
					newForm = form;
				}
				// console.log('newForm', newForm);
				// console.log('newForm', newForm);
				const state = this.$store._modules.root.state;
				// console.log('state', state);

				let profile = JSON.parse(JSON.stringify(state.Merchants.merchantProfile))
				// console.log('state.Merchants.merchantProfile', state.Merchants.merchantProfile);
				// console.log("Object.keys(state.Merchants.merchantProfile).length != 0", Object.keys(state.Merchants.merchantProfile).length != 0);

				let selectedProfile = JSON.parse(JSON.stringify(state.Merchants.selectedMerchantProfile))
				// console.log('state.Merchants.selectedMerchantProfile', state.Merchants.selectedMerchantProfile);
				// console.log("selectedProfile", selectedProfile);
				// console.log("selectedProfile.id", selectedProfile.id);

				if(Object.keys(state.Merchants.merchantProfile).length == 0) {
					// console.log("exeuted before");
					newForm['merchant'] = selectedProfile['id']
					// console.log("35 newForm", newForm);
					resolve(newForm);
				}else {
					// console.log("executed after");
					newForm['merchant'] = profile['id']
					// console.log("39 newForm", newForm);
					resolve(newForm);
				}
			});
		},

  },
  mounted() {

  },
  created() {

  }
}