<template>
	<div>
		<template>
			<!-- Left Drawer -->
			<div class="row">
				<div class="col-12 my-7">
					<div v-if="!onDrawer" class="headline-3 text-center text-white mb-2">
						{{userPermissionLevel}}
					</div>
					<div v-if="!onDrawer" class="headline-5 text-center text-white m-0">
						{{ GET_USER_PROFILE.full_name }}
					</div>
				</div>
			</div>
	

			<v-divider></v-divider>

			<v-list dense>
				<router-link 
					v-for="item in items"
					:key="item.id"
					:to="{ path: item.path }"
					active-class>

						<v-list-item
							:class="{activeClass: navRoute == item.name}"
						>
							<v-list-item-icon color="primary">
								<v-icon>{{ item.icon }}</v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title>{{ item.title }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

				</router-link>
				
			</v-list>
		</template>


	</div>
</template>

<script>
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
import moment from 'moment';

import { web, main, superuser, merchant } from "@/routes/app-navigation"


export default {
  name: 'leftDrawerComponent',
  components: {
	},
	mixins: [

	],
	props: {
		onDrawer: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			//Main Data
			navRoute: null,
			//vuetify stuff

			item: 1
		}
	},
	methods: {
		testButton() {

		},
		selectMenuItem(evt) {
			console.log("activeClass evt", evt)
		}

	},
	computed: {
		...mapState([]),
		...mapGetters(["GET_USER_PROFILE", "GET_USER_LEVEL"]),
		activeClass(evt) {
		// console.log("activeClass evt", evt)
		},
		userPermissionLevel() {
			if(this.GET_USER_PROFILE.is_superuser) {
				return "Superuser"
			} else if(this.GET_USER_PROFILE.is_merchant) {
				return "Merchant"
			} 
		},
		items() {
			// console.log('this.$route.meta.navBar', this.$route.meta.navBar)
			if(this.GET_USER_PROFILE.is_merchant) {
				return merchant
			} else {
				return this.$route.meta.navBar
			}
			
		}
	},
	watch: {
		$route(to, from) {
			// react to route changes...
			// console.log('to', to)
			// console.log('from', from)
			// this.navRoute = to.name.toString()
			// switch(this.navRoute) {
			// 	case "Merchant":
			// 		this.items = merchant
			// 		break
			// 	case "Superuser":
			// 		this.items = superuser
			// 		break
			// 	case "Admin":
			// 		this.items = main
			// 		break
			// }
			// console.log("this.navRoute", this.navRoute)
    }

	},
	created() {
		// console.log("created this.$route.name", this.$route.name)
		this.navRoute = this.$route.name


	}
}
</script>
<style scoped lang="scss">
aside {
	z-index: 10000;
}


</style>
