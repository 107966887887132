<template>
  <v-theme-provider :dark="dark">
    <div>
			<v-icon
      :size="75"
      color="cyan"
    >
      fa-credit-card
    </v-icon>

      <!-- <base-img
        :src="require('@/assets/static/website/zero-logo-dark.svg')"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      /> -->

      <base-title
        size="body-1"
        space="4"
        title="Online Credit Card Processing for Medical Offices"
        weight="regular"
      />

      <base-body>
       Due to the rising demand for a safe and secure way to process credit card transactions, we are pleased to announce the release of Patient Payment Portal, a web gateway for medical offices.
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
				to="/contact-us"
      >
        More Info
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
  }
</script>

<style lang="sass" scoped>

</style>
