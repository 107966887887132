<template>
		<v-parallax
			:height="300"
			:src="require('@/assets/static/web-images/doctor-bill-hero-1400x900.jpg')"
		>
			<v-row
				align="center"
				justify="center"
			>
				<v-col
					class="text-center"
					cols="12"
				>
					<h1 class="headline-1 font-weight-thin mb-4">
						Patient Payment Portal
					</h1>
					<h4 class="subheading">
						Start taking COVID safe payments today!
					</h4>
				</v-col>
			</v-row>
		</v-parallax>
</template>

<script>
  export default {
    name: 'BaseParallax',

    props: {

    },
  }
</script>

<style scoped lang="scss">
.v-parallax__image {
  transform: none !important;
  width: 100% !important;
}
</style>
