<template>
	<div class="flex justify-center">
		<div class="container mt-4">
			<DxScrollView
				show-scrollbar="always"
				width="100%"
				direction="horizontal"
				:scroll-by-thumb="true">

				<!-- Main Container Row -->
				<div class="row">
					<!-- Main Content Left Area -->
					<v-col cols="12" lg="6" class="px-2">
						<baseUserFormComponent
							ref="baseEmployeeFormRef"
							@UserData="SetUserData"
							@PATCHEmployee="PATCHEmployee"
							@clearCheckbox="clearCheckbox">
							<template slot="title">
								Add New Employee
							</template>

							<template v-if="GET_USER_PROFILE.is_superuser" slot="parent-component">
								<div class="row">
									<div class="header">
										Merchant
									</div>
								</div>

								<parentSelectionComponent 
									ref="parentSelectionRef"
									:parentForm="employeeForm"/>
							</template>

							<template slot="profile-info">
								<div class="row">
									<div class="col-12">
										<v-file-input
											accept="image/*"
											label="File input"
											v-model="profile_img"
										></v-file-input>
									</div>
								</div>
							</template>

						</baseUserFormComponent>

					</v-col>
					<!-- END Main Content Left Area -->
					<!-- Right Panel -->
					<v-col cols="12" lg="6" class="px-2">

						<template>
							<v-card
								:min-width="850">
								<v-card-title class="mb-1 bg-primary text-white">
									<div class="row">
										<div class="col-6">
											<div class="title">
												Employee List
											</div>
										</div>
										<div class="col-6 flex items-center">
											<v-text-field
												class="m-0 p-0"
												color="teal"
												dark
												v-model="search"
												append-icon="mdi-magnify"
												label="Search"
												single-line
												hide-details
											></v-text-field>
										</div>
									</div>
								</v-card-title>

								<dataTableComponent
									ref="employeeDataTableRef"
									:headerTitles="headers"
									:searchData="search"
									:listData="updatedListData"
									@updateItem="updateItem"
									@clearData="clearData"
									@deleteItem="deleteItem">
								</dataTableComponent>

							</v-card>

						</template>

						<v-row class="justify-start my-4">
							<v-col cols="3" class="text-left" v-if="$can('view', 'superuser')">
								<v-btn
									color="warning"
									class="mr-4"
									@click="testButton"
								>
									Test
								</v-btn>
							</v-col>
							<v-col cols="3">
								<v-btn
									color="warning"
									class="mr-4"
									@click="testButton"
								>
									Test
								</v-btn>
							</v-col>
						
						</v-row>

					</v-col>
					<!-- END Right Panel -->
				</div>
				<!-- END Main Container Row -->
			</DxScrollView>
			
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'
import { UniversalMixin } from "@/mixins/universal-mixins"

//Components
import baseUserFormComponent from "@/components/forms/base-user-form"
const dataTableComponent = import("@/components/tables/data-table-component")
import parentSelectionComponent from "@/components/parent-selectors/parent-merchant-select-component"

import LoadingState from '@/components/component-management/loading-state-component'
import ErrorState from '@/components/component-management/error-state-component'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'

// Event baseUserForm
import { Bus } from "@/js/bus"

export default {
  name: 'merchantManageEmployees',
  components: {
		DxScrollView,
		baseUserFormComponent,
		parentSelectionComponent,
		dataTableComponent: () => ({
			component: dataTableComponent,
			loading: LoadingState,
			error: ErrorState,
			//Delay before showoing loader
			delay:100,
			//Timeout to show error component
			timeout: 5000
		}),
  },
  mixins: [
		FormMixins,
		UniversalMixin
  ],
  props: {

  },
  data () {
    return {
      // Main Data

      // Component Data
			search: '',
			headers: [
				{	text: "ID",	align: 'center',	sortable: true,	value: 'id' },
				{ text: 'Name', align: 'center',	sortable: true, value: 'user_obj.full_name' },
				{ text: 'Merchant', align: 'center',	sortable: true, value: 'merchant_obj.dba_name' },
				{ text: 'Email', align: 'center',	sortable: true, value: 'user_obj.email' },
				{ text: 'Phone', align: 'center',	sortable: true, value: 'user_obj.mobile_phone' },
				{ text: 'Last Login', align: 'center',	sortable: true, value: 'user_obj.last_login' },
				{ text: 'Is Active', align: 'center',	sortable: true, value: 'user_obj.is_active' },
			],

			listData: {
				tableId: "employeeListTable",
				list: new Array()
			},

			// Form Data
			profile_img: null,
			employeeForm: {
				id: null,
				merchant: null,
				merchant_obj: {},
				user: null,
				profile_img: null,
			},
			baseUserForm: {}

    }
  },
  methods: {
    async testButton() {
			// console.log("GET_USER_PROFILE", this.GET_USER_PROFILE);
			// console.log("GET_MERCHANT_EMPLOYEES_LIST", this.GET_MERCHANT_EMPLOYEES_LIST);
			// console.log("employeeForm", this.employeeForm);
			// console.log("GET_MERCHANT_LIST", this.GET_MERCHANT_LIST);
			
			
		},
		clearCheckbox(evt) {
			// console.log("clearCheckbox");
			// clear checkbox on datatable
			// console.log("this.$refs", this.$refs);
			this.$refs.employeeDataTableRef.clearItem()
		},
		SetEmployeeMerchant(evt) { // Set ID in parent selector from SuperUserDashboard Selection
			// console.log("SetEmployeeMerchant evt",evt);
			this.employeeForm.merchant = evt
		},
		async SetUserData(evt) {
			return new Promise(async (resolve, reject) => {
				// console.log("SetUserData evt", evt)
				this.baseUserForm = evt
				let merchantId = await this.getUsermerchantID()
				// console.log("merchantId", merchantId);
				// console.log("baseUserForm", this.baseUserForm)
				if(merchantId != undefined) {
					this.employeeForm.merchant = merchantId
					resolve()
				} else {
					this.employeeForm.merchant = null
					resolve()
				}
			})			
		},
		//From Datatable Update To Form Fields
		async updateItem(evt) {
			// console.log('updateItem evt', evt)
			
			var getUserObj
			getUserObj = await this.$store.dispatch("GETSelectedEmployeeById", {id: evt.id});
			// console.log('getUserObj', getUserObj);
			
			this.employeeForm.id = evt.id
			this.employeeForm.profile_img = evt.profile_img
			this.employeeForm.merchant = evt.merchant

			this.$refs.baseEmployeeFormRef.updateForm(getUserObj)
			this.$store.commit("SET_SELECTED_USER", getUserObj)

			// console.log('updateItem this.employeeForm', this.employeeForm)
		},
		clearData() {
			// console.log("clearData employees");
			// console.log("this.$refs", this.$refs);

			for(let key in this.employeeForm) {
				this.employeeForm[key] = null
			}
			this.baseUserForm = {}
			this.profile_img = null

			this.$refs.baseEmployeeFormRef.resetForm()
		},
		async createUser() { // Called from baseUserForm
			//Create User account first, Employee second
			// console.log("Employee CreateUser", this.employeeForm)
			// console.log("Employee CreateUser baseUserForm", this.baseUserForm)

			try {
				// console.log(this.baseUserForm)

				//By the time the user is created, the image is attached
				if(this.profile_img) {
					var profileReader = new FileReader();
					profileReader.readAsDataURL(this.profile_img);
					profileReader.onload = (e) => {
						this.employeeForm.profile_img = e.target.result;
					};
				}
				
				// console.log("Employee CreateUser", this.employeeForm)

				//Create Base User First
				// console.log("Create User this.baseUserForm.user", this.baseUserForm);
				let userResponse = await this.$store.dispatch("POSTUser", this.baseUserForm)
				// console.log("userResponse", userResponse)

				if(userResponse.status === 200 || userResponse.status === 201) {
					// console.log('this.employeeForm', this.employeeForm)
					this.employeeForm.user = userResponse.data.id
					// console.log("this.GET_SELECTED_MERCHANT_PROFILE", this.GET_SELECTED_MERCHANT_PROFILE);
					// console.log("this.GET_MERCHANT_PROFILE", this.GET_MERCHANT_PROFILE);
					// console.log("Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length", Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length);

					// console.log("this.employeeForm.", this.employeeForm);

					//Create Employee Account
					let employee = await this.$store.dispatch("POSTEmployee", this.employeeForm)
					// console.log("employee.data", employee.data);
					// console.log("Object.keys(employee.data).length != 0", Object.keys(employee.data).length != 0);
					// console.log("Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0", Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0);

					if(Object.keys(employee.data).length != 0) {
						if(Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0) {
							// console.log("this.GET_SELECTED_MERCHANT_PROFILE length !=0");
							let employees = await this.$store.dispatch("GETSelectedMerchantEmployeeList", {id: this.GET_SELECTED_MERCHANT_PROFILE.id}, {root: true})
							// console.log("1employees", employees);
						} else {
							// console.log("this.GET_SELECTED_MERCHANT_PROFILE length == 0");
							let employees = await this.$store.dispatch("GETMerchantEmployeeList", {id: employee.data.merchant}, {root: true})
							// console.log("1employees", employees);
						}

						this.$refs.baseEmployeeFormRef.resetForm()
						this.baseUserForm = null
						this.employeeForm.merchant = employee.data.merchant
					}
				} 
				
	
			} catch(error) {
				// console.error('error', error)
			}
		},
		SetEmployeeMerchant(evt) {
			// console.log("SetEmployeeMerchant", evt);
			this.employeeForm.merchant = evt
		},
		async getUsermerchantID() {
			return new Promise(async (resolve, reject) => {
				let response = await this.setMerchantIDforPOST(JSON.parse(JSON.stringify(this.employeeForm)))
				// console.log('merchantform response', response)
				// console.log('this.employeeForm', this.employeeForm)
				if(response.merchant == undefined) {
					resolve(this.employeeForm.merchant)
					// this.baseUserForm.merchant = this.employeeForm.merchant
				}
				else {
					resolve(response.merchant)
					// this.baseUserForm.merchant = response.merchant
				}
			})
		},
		//PATCH Update call from Base user Component
		async PATCHEmployee(baseuser) {
			//Make API Call
			//Add Profile image too
			this.employeeForm.profile_img = null

			if(this.profile_img) {
				var profileReader = new FileReader();
				profileReader.readAsDataURL(this.profile_img);
				profileReader.onload = async (e) => {
					this.employeeForm.profile_img = e.target.result;

					this.employeeForm.user = baseuser.id
					await this.$store.dispatch("PATCHEmployee", this.employeeForm)

					// Get updated list of Employees
					let employees = await this.$store.dispatch("GETMerchantEmployeeList", {id: this.GET_EMPLOYEE_PROFILE.merchant})
					// console.log("employees", employees);

				}
			} else {
				this.employeeForm.user = baseuser.id
				await this.$store.dispatch("PATCHEmployee", this.employeeForm)

				// Get updated list of Employees
				let employees = await this.$store.dispatch("GETMerchantEmployeeList", {id: this.GET_EMPLOYEE_PROFILE.merchant})
				// console.log("employees", employees);
			}
			this.mountData()
			
		},
		async deleteItem(evt) {
			// console.log('deleteItem evt', evt)
			const userObj = {id: evt.user, is_active: false}
			let response = await this.$store.dispatch("PATCHBaseUser", userObj)
			// console.log('delete response', response)
			if(response.status === 200 || response.status === 201) {
				if(Object.values(this.GET_SELECTED_MERCHANT_PROFILE).length != 0) {
					await this.$store.dispatch("GETSelectedMerchantEmployeeList", {id: this.GET_SELECTED_MERCHANT_PROFILE.id})
				} else {
					await this.$store.dispatch("GETMerchantEmployeeList", {id: this.GET_MERCHANT_PROFILE.id})
				}
				this.mountData()
			}
		},
		mountData() {
			return new Promise(async (resolve, reject) => {
			// console.log("this.GET_SELECTED_MERCHANT_EMPLOYEES_LIST", this.GET_SELECTED_MERCHANT_EMPLOYEES_LIST);
			// console.log("GET_SELECTED_MERCHANT_PROFILE", this.GET_SELECTED_MERCHANT_PROFILE);

			if(Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0) {
				// console.log("Has GET_SELECTED_MERCHANT_EMPLOYEES_LIST");
				// this.listData.list = this.GET_SELECTED_MERCHANT_EMPLOYEES_LIST
				// console.log("this.GET_SELECTED_MERCHANT_PROFILE.id", this.GET_SELECTED_MERCHANT_PROFILE.id);
				
				this.employeeForm['merchant'] = this.GET_SELECTED_MERCHANT_PROFILE.id

				// console.log("this.employeeForm.merchant", this.employeeForm.merchant);
				
			}else {
				// this.listData.list = this.GET_MERCHANT_EMPLOYEES_LIST
				// console.log("this.GET_MERCHANT_PROFILE.id",this.GET_MERCHANT_PROFILE.id);
				
				this.employeeForm['merchant'] = this.GET_MERCHANT_PROFILE.id

				// console.log("this.employeeForm.merchant", this.employeeForm.merchant);
			}

			// console.log("this.employeeForm", this.employeeForm);
			
			resolve()
			})
		}

  },
  computed: {
    ...mapState(["Employees", "Notifications"]),
    ...mapGetters(["GET_EMPLOYEE_LIST", "GET_USER_PROFILE", 'GET_MERCHANT_EMPLOYEES_LIST', "GET_EMPLOYEE_PROFILE", 
									"GET_SELECTED_MERCHANT_PROFILE", "GET_MERCHANT_PROFILE", "GET_SELECTED_MERCHANT_EMPLOYEES_LIST"]),
		updatedListData() {
			// console.log("updatedListData computed");
			var list = []
			if(Object.keys(this.GET_SELECTED_MERCHANT_EMPLOYEES_LIST).length != 0) {
				// console.log("Has GET_SELECTED_MERCHANT_EMPLOYEES_LIST");
				list = this.GET_SELECTED_MERCHANT_EMPLOYEES_LIST
			}else {
				list = this.GET_MERCHANT_EMPLOYEES_LIST
				// console.log("list", list);
			}
			
			return list

		}
		

	},
	created() {

	},
  async mounted () {
		//Get Merchant ID of logged in User if User is associated with a merchant
		// console.log('employees MOUNTED')
		await this.getUsermerchantID()
		await this.mountData()


		// console.log("Mounted");
		// Bus.$on('SetEEMerchant', (evt)=> {
		// 	console.log("SetEEMerchant Bus evt", evt)
		// 	this.SetEmployeeMerchant(evt)
		// })
		
  },
	beforeUnmount() {
		// console.log("The page ahs been unmounted"); 

	}
}

</script>
<style scoped lang="scss">

</style>
