
<template>
	<div class="flex justify-center">
		<div class="container">
			<!-- Main Container Row -->
			<div class="row">
				<!-- Main Content Left Area -->
				<div class="col-12">
					
					<template>
						<!-- <v-row>
							<div class="header">
								Select Merchant
							</div>
						</v-row>

						<v-row v-if="GET_USER_PROFILE.is_superuser">
							<v-col cols="12">
								<parentSelectionComponent 
									ref="parentFormRef"
									:parentForm="apiForm"/>
							</v-col>
						</v-row> -->
						<v-expansion-panels>
							<v-expansion-panel>
								<v-expansion-panel-header>API Settings</v-expansion-panel-header>
								<v-expansion-panel-content>
									<template>
										Credit Card Processing API Key
									</template>
									<template>
										<div class="row">
											<v-card 	
												class="p-4"
												width="100%">
												
												<v-form
													ref="apiFormRef"
													v-model="validAPIForm">
													<div class="row">
														<div class="col-6">
															<v-switch
																v-if="showEditToggle"
																:disabled="checkPermission()"
																v-model="enableEdit"
																label="Edit"
															></v-switch>
														</div>
													</div>
													<div class="row">
														<div class="col-6">
															<v-text-field
																label="Merchant ID"
																v-model="apiForm.ssl_merchant_id"
																:disabled="!enableEdit"
															></v-text-field>
														</div>
														<div class="col-6">
															<v-select
																:items="processors"
																label="Processor"
																v-model="apiForm.processor_name"
																:disabled="!enableEdit"
															></v-select>
														</div>
													</div>
													<div class="row">
														<div class="col-12">
															<v-text-field
																label="Pin"
																v-model="apiForm.ssl_pin"
																:disabled="!enableEdit"
															></v-text-field>
														</div>
													</div>

													<div class="row">
														<div class="col-6">
															<v-text-field
																label="Account ID"
																v-model="apiForm.ssl_account_id"
																:disabled="!enableEdit"
															></v-text-field>
														</div>
														<div class="col-6">
															<v-text-field
																label="User ID"
																v-model="apiForm.ssl_user_id"
																:disabled="!enableEdit"
															></v-text-field>
														</div>
													</div>
													<div class="row">
														<div class="col-6">
															<v-switch
																v-model="apiForm.is_demo"
																label="Is Demo?"
																:disabled="!enableEdit"
															></v-switch>
														</div>
														
													</div>
												</v-form>
												
											</v-card>
										</div>
									</template>

									<template>
										<div class="row mt-4 justify-end">
											<div class="col-3 text-center mx-4">
												<v-btn
												@click="cancel"
												color="warning">
													Cancel
												</v-btn>
											</div>
											<div class="col-3">
												<v-btn
												@click="submit"
												color="primary">
													Submit
												</v-btn>
											</div>
										</div>
									</template>
								</v-expansion-panel-content>
							</v-expansion-panel>


						</v-expansion-panels>

						
					</template>

					<v-row v-if="$can('view', 'admin')">
						<div class="col-3">
							<v-btn
								@click="testButton"
								color="primary">
									Testing
							</v-btn>
					</div>
					</v-row>

				</div>
				<!-- END Main Content Left Area -->
			</div>
			<!-- END Main Container Row -->
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'
import axios from "axios"

//Components
import parentSelectionComponent from "@/components/parent-selectors/parent-merchant-select-component"

//Mixins
import { UniversalMixin } from "@/mixins/universal-mixins"

export default {
  name: 'merchantSettings',
  components: {
		parentSelectionComponent
  },
  mixins: [
		UniversalMixin
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			validAPIForm: false,
			processors: [
				"Elavon",
				"Forte"
			],
			isSuperuser: false,
			
      //Form Data
			showEditToggle: true,
			enableEdit: false,
			apiForm: {
				merchant: null,
				ssl_pin: null,
				processor_name: null,
				ssl_merchant_id: null,
				ssl_account_id: null,
				ssl_user_id: null,
				is_demo: false,
			}



    }
  },
  methods: {
    testButton () {
			// console.log("this.GET_FULL_PROFILE", this.GET_USER_PROFILE);
			console.log("GET_MERCHANT_PROFILE", this.GET_MERCHANT_PROFILE);
			console.log("GET_SELECTED_MERCHANT_PROFILE", this.GET_SELECTED_MERCHANT_PROFILE);
		},
		async submit() {
			let valid = this.$refs.apiFormRef.validate()
			if(this.GET_USER_PROFILE.is_superuser) {
				// let parent = this.$refs.parentFormRef.validateForm() // All superusers are not connected to a merchant
				if(valid) {
					var newForm = JSON.parse(JSON.stringify(this.apiForm))
					// console.log("newForm", newForm)
					newForm = await this.setMerchantIDforPOST(this.apiForm)
					// console.log("newForm", newForm)

					if(this.apiForm.processor_name === "Elavon") {
						let key = await this.$store.dispatch("POSTElavonAPIKey", newForm)
						if(key) {
							this.enableEdit = false
						}

					}
				}
			} else {
				if(valid) {
					var newForm = JSON.parse(JSON.stringify(this.apiForm))
					// console.log("newForm", newForm)
					if(newForm.merchant == null) {
						newForm = await this.setMerchantIDforPOST(this.apiForm)
						// console.log("newForm", newForm)
					}			
					if(this.apiForm.processor_name === "Elavon") {
						let key = await this.$store.dispatch("POSTElavonAPIKey", newForm)
						if(key) {
							this.enableEdit = false
						}

					}
				}
			}
			
		},
		checkPermission() {
			// console.log("checkPermission on toggle");
			// console.log("this.GET_USER_PROFILE.is_admin", this.GET_USER_PROFILE);
			if(this.GET_USER_PROFILE.is_admin || this.GET_USER_PROFILE.is_superuser) {
				return false
			} 
			return true
		},
		mountData() {
			// console.log('mountData');
			// update from with data from Vuex

		},
		cancel() {
			// console.log('cancel');
			// console.log('this.apiForm', this.apiForm);
			// console.log('this.APIKeys.elavonAPIKeyProfile', this.APIKeys.elavonAPIKeyProfile);
			// this.$router.go(-1)
		},
		async updateParent() {
			if(this.GET_USER_PROFILE.is_merchant) {
				// console.log("Yes, Is merchant User")
				// console.log("this.GET_USER_PROFILE", this.GET_USER_PROFILE)
				// console.log("this.GET_FULL_PROFILE", this.GET_FULL_PROFILE)
				this.apiForm.merchant = this.GET_FULL_PROFILE.merchant
				// console.log("this.apiForm", this.apiForm)
			} 
		},


  },
  computed: {
    ...mapState(["APIKeys"]),
    ...mapGetters(['GET_USER_PROFILE', "GET_FULL_PROFILE", "GET_MERCHANT_PROFILE", "GET_SELECTED_MERCHANT_PROFILE"])

	},

  async mounted () {
		// await this.mountData()
		await this.updateParent()
  }
}
</script>
<style scoped lang="scss">

</style>
