import Vue from 'vue'
import axios from 'axios'

import apiRoutes from '@/js/api-routes'

export const Transactions = {
  namespace: true,
  name: 'transactions',
  state: {
		transactionList: [],
		selectedTransactionList: []
  },
  mutations: {
		CLEAR_TRANSACTION_STATE(state, payload) {
			state.transactionList= []
			state.selectedTransactionList= []
		},
		SET_TRANSACTIONS(state, payload) { // Logged In User account
			// console.log("SET_TRANSACTIONS payload", payload)
			// Vue.set(state.transactionList, state.transactionList.length, payload)
			state.transactionList = payload
		},
		SET_TRANSACTIONS_LIST(state, payload) { // Selected Account
			state.selectedTransactionList = payload
		},
		PUSH_TRANSACTION(state, payload) {
			// console.log("PUSH_TRANSACTION payload", PUSH_TRANSACTION);
			state.transactionList.push(payload)
		}
  },
  actions: {
		//To Post a transaction use Converge.js
		//Only Get Transactions Here
		//GET LISTS
		FILTERTransactionsByMerchantId({ commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				// console.log("FILTERTransactionsByMerchantId payload", payload)
				var year = new Date().getFullYear()
				var month = new Date().getMonth() + 1
				try {
					const endpoint = `elavon-transactions/?ordering=-id&page=1&start=${year}-${month}-01T00:00:00Z&merchant=`
					const type = 'Get Merchant Transactions'
					// console.log("FILTERTransactionsByMerchantId endpoint", endpoint)
					const response = await apiRoutes.FILTERListById(dispatch, rootState, payload, endpoint, type)
					// console.log("FILTERTransactionsByMerchantId response", response)
					if (response.status === 200) {
						if(Object.keys(rootState.Merchants.selectedMerchantProfile).length != 0) {
							commit('SET_TRANSACTIONS_LIST', response.data)
						} else {
							commit('SET_TRANSACTIONS', response.data)
						}
						resolve(response.data)
					}
				} catch (error) {
					resolve()
				}
			}).catch(error => {
				if (error.response) {
					reject(error)
				}
			})
		},
		SetTransactionsListByMerchantId({ commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				// console.log("FILTERTransactionsByMerchantId payload", payload)
				// console.log("rootState.Merchants.selectedMerchantProfile",rootState.Merchants.selectedMerchantProfile);
				var year = new Date().getFullYear()
				var month = new Date().getMonth() + 1
				try {
					const endpoint = `elavon-transactions/?ordering=-id&page=1&start=${year}-${month}-01T00:00:00Z&merchant=`
					const type = 'Get Merchant Transactions'
					// console.log("FILTERTransactionsByMerchantId endpoint", endpoint)
					const response = await apiRoutes.FILTERListById(dispatch, rootState, payload, endpoint, type)
					// console.log("FILTERTransactionsByMerchantId response", response)
					if (response.status === 200) {
						if(Object.keys(rootState.Merchants.selectedMerchantProfile).length != 0) {
							commit('SET_TRANSACTIONS_LIST', response.data)
						} else {
							commit('SET_TRANSACTIONS', response.data)
						}
						resolve(response.data)
					}
				} catch (error) {
					resolve()
				}
			}).catch(error => {
				if (error.response) {
					reject(error)
				}
			})
		},
		FILTERTransactionsByFilterFields({ commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				// console.log('FILTERTransactionsByFilterFields payload', payload)
				var endpoint = `elavon-transactions/?page=${payload.page}&start=${payload.start}&end=${payload.end}`
				payload.result? endpoint += '&result=' + payload.result: endpoint
				payload.last_name? endpoint += '&last_name=' + payload.last_name: endpoint
				payload.last_four? endpoint += '&last_four=' + payload.last_four: endpoint
				payload.acct_no? endpoint += '&acct_no=' + payload.acct_no: endpoint
				endpoint += '&merchant='
				try {
					const type = 'Get Merchant Transactions'
					const response = await apiRoutes.FILTERListById(dispatch, rootState, payload, endpoint, type)
					if (response.status === 200) {
						if(Object.keys(rootState.Merchants.selectedMerchantProfile).length != 0) {
							commit('SET_TRANSACTIONS_LIST', response.data)
						} else {
							commit('SET_TRANSACTIONS', response.data)
						}
						
						resolve(response.data)
					}
				} catch (error) {
					resolve()
				}
			}).catch(error => {
				if (error.response) {
					reject(error)
				}
			})
		},
		//Next Previous Transacitons
		NextPreviousTransactions({ commit, dispatch, rootState }, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					axios.get('/django' + payload, {headers: {Authorization: rootState.Auth.token}}).then(response => {
						// console.log('response', response)
						if (response.status === 200) {
							if(Object.keys(rootState.Merchants.selectedMerchantProfile).length != 0) {
								commit('SET_TRANSACTIONS_LIST', response.data)
							} else {
								commit('SET_TRANSACTIONS', response.data)
							}
							resolve(response.data)
						}
					})
				} catch (error) {
					resolve()
				}
			}).catch(error => {
				if (error.response) {
					reject(error)
				}
			})
		},
		clearTransactionState({ commit, dispatch, rootState }) {
			commit("CLEAR_TRANSACTION_STATE")
		},
		// addTransaction({commit, state}, payload) {
		// 	console.log("addTransaction payload", payload);
		// 	console.log("state.transactionList.length", state);
		// 	commit("PUSH_TRANSACTION", payload)
		// 	// console.log("state.transactionList.length", state.transactionList.length);
		// }
	
  },
  getters: {
		GET_TRANSACTION_LIST(state) {
			return state.transactionList
		},
		GET_SELECTED_TRANSACTION_LIST(state) {
			return state.selectedTransactionList
		}
  }
}
