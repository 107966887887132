export const stateNames = [
    {abbr: "AL", state: "Alabama"},
    {abbr: "AK", state: "Alaska"},
    {abbr: "AS", state: "American Samoa"},
    {abbr: "AZ", state: "Arizona"},
    {abbr: "AR", state: "Arkansas"},
    {abbr: "CA", state: "California"},
    {abbr: "CO", state: "Colorado"},
    {abbr: "CT", state: "Connecticut"},
    {abbr: "DE", state: "Delaware"},
    {abbr: "DC", state: "District Of Columbia"},
    {abbr: "FM", state: "Federated States Of Micronesia"},
    {abbr: "FL", state: "Florida"},
    {abbr: "GA", state: "Georgia"},
    {abbr: "GU", state: "Guam"},
    {abbr: "HI", state: "Hawaii"},
    {abbr: "ID", state: "Idaho"},
    {abbr: "IL", state: "Illinois"},
    {abbr: "IN", state: "Indiana"},
    {abbr: "IA", state: "Iowa"},
    {abbr: "KS", state: "Kansas"},
    {abbr: "KY", state: "Kentucky"},
    {abbr: "LA", state: "Louisiana"},
    {abbr: "ME", state: "Maine"},
    {abbr: "MH", state: "Marshall Islands"},
    {abbr: "MD", state: "Maryland"},
    {abbr: "MA", state: "Massachusetts"},
    {abbr: "MI", state: "Michigan"},
    {abbr: "MN", state: "Minnesota"},
    {abbr: "MS", state: "Mississippi"},
    {abbr: "MO", state: "Missouri"},
    {abbr: "MT", state: "Montana"},
    {abbr: "NE", state: "Nebraska"},
    {abbr: "NV", state: "Nevada"},
    {abbr: "NH", state: "New Hampshire"},
    {abbr: "NJ", state: "New Jersey"},
    {abbr: "NM", state: "New Mexico"},
    {abbr: "NY", state: "New York"},
    {abbr: "NC", state: "North Carolina"},
    {abbr: "ND", state: "North Dakota"},
    {abbr: "MP", state: "Northern Mariana Islands"},
    {abbr: "OH", state: "Ohio"},
    {abbr: "OK", state: "Oklahoma"},
    {abbr: "OR", state: "Oregon"},
    {abbr: "PW", state: "Palau"},
    {abbr: "PA", state: "Pennsylvania"},
    {abbr: "PR", state: "Puerto Rico"},
    {abbr: "RI", state: "Rhode Island"},
    {abbr: "SC", state: "South Carolina"},
    {abbr: "SD", state: "South Dakota"},
    {abbr: "TN", state: "Tennessee"},
    {abbr: "TX", state: "Texas"},
    {abbr: "UT", state: "Utah"},
    {abbr: "VT", state: "Vermont"},
    {abbr: "VI", state: "Virgin Islands"},
    {abbr: "VA", state: "Virginia"},
    {abbr: "WA", state: "Washington"},
    {abbr: "WV", state: "West Virginia"},
    {abbr: "WI", state: "Wisconsin"},
    {abbr: "WY", state: "Wyoming"}
];