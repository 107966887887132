export const codes = 
[
  {
    "code": "3001",
    "errorName": "Gateway generated error",
    "message": "#."
  },
  {
    "code": "3002",
    "errorName": "Adapter generated error",
    "message": "#."
  },
  {
    "code": "3003",
    "errorName": "Processing error",
    "message": "There was an issue while processing your request. If this issue persists please contact Technical Support at 1-800-377-3962"
  },
  {
    "code": "4000",
    "errorName": "VID Not Supplied",
    "message": "The Converge ID was not supplied in the authorization request."
  },
  {
    "code": "4002",
    "errorName": "HTTP Trans Not Allowed",
    "message": "HTTP POST transactions are not allowed for this account."
  },
  {
    "code": "4003",
    "errorName": "HTTP Referrer Invalid",
    "message": "HTTP POST transactions are not allowed for this HTTP Referrer."
  },
  {
    "code": "4005",
    "errorName": "E-mail Address Invalid",
    "message": "The e-mail address supplied in the authorization request appears to be invalid."
  },
  {
    "code": "4006",
    "errorName": "CVV2 Not Requested With Data",
    "message": "The CVV2 indicator was not identified in the authorization request."
  },
  {
    "code": "4007",
    "errorName": "CVV2 Requested But No Data",
    "message": "The CVV2 request failed due to missing one or more pieces of required data."
  },
  {
    "code": "4009",
    "errorName": "Required Field Not Supplied",
    "message": "One or more required fields were not included."
  },
  {
    "code": "4010",
    "errorName": "Invalid Transaction Type",
    "message": "The authorization request included an invalid Transaction Type"
  },
  {
    "code": "4011",
    "errorName": "Receipt URL Missing",
    "message": "The Receipt URL supplied in the authorization request appears to be blank or invalid."
  },
  {
    "code": "4013",
    "errorName": "PIN Not Supplied",
    "message": "The PIN was not supplied in the authorization request."
  },
  {
    "code": "4014",
    "errorName": "Not Permitted",
    "message": "This terminal or user ID is not permitted to process this transaction type."
  },
  {
    "code": "4016",
    "errorName": "Permission Denied",
    "message": "This account does not have permission to process # transactions."
  },
  {
    "code": "4017",
    "errorName": "Time-Out",
    "message": "The request has timed out. The allotted time to complete the request has ended. Please try again."
  },
  {
    "code": "4018",
    "errorName": "Settlement is in progress",
    "message": "Settlement is in progress"
  },
  {
    "code": "4019",
    "errorName": "User ID not supplied",
    "message": "The User ID was not supplied in the authorization request."
  },
  {
    "code": "4022",
    "errorName": "The system is unavailable",
    "message": "The system is unavailable. Please try again later."
  },
  {
    "code": "4023",
    "errorName": "Settlement is not allowed for this terminal",
    "message": "Settlement is not allowed for this terminal."
  },
  {
    "code": "4025",
    "errorName": "Invalid Credentials",
    "message": "The credentials supplied in the authorization request are invalid."
  },
  {
    "code": "5000",
    "errorName": "Credit Card Number Invalid",
    "message": "The Credit Card Number supplied in the authorization request appears to be invalid."
  },
  {
    "code": "5001",
    "errorName": "Exp Date Invalid",
    "message": "The Credit Card Expiration Date supplied in the authorization request appears to be invalid."
  },
  {
    "code": "5002",
    "errorName": "Amount Invalid",
    "message": "The amount supplied in the authorization request appears to be invalid."
  },
  {
    "code": "5003",
    "errorName": "Approval Code/No Force",
    "message": "This transaction supplied a Force Approval Code for a non-Force transaction"
  },
  {
    "code": "5004",
    "errorName": "Invalid Approval Code",
    "message": "The Force Approval Code supplied in the authorization request appears to be invalid or blank. The Force Approval Code must be 6 or less alphanumeric characters."
  },
  {
    "code": "5005",
    "errorName": "Field Character Limit Exceeded",
    "message": "The value for the # field is too long. You can enter # characters (maximum). Your entry contains # characters. If you entered the value for this field"
  },
  {
    "code": "5006",
    "errorName": "Refund Amount Exceeds Limit",
    "message": "The refund amount for this transaction ($#) may not exceed $#."
  },
  {
    "code": "5007",
    "errorName": "Sales Tax Invalid",
    "message": "The Sales Tax supplied in the authorization request appears to be invalid."
  },
  {
    "code": "5008",
    "errorName": "Invalid Account Type",
    "message": "This PINless Debit Transaction contains invalid account type. Account type can be checking or saving."
  },
  {
    "code": "5009",
    "errorName": "Invalid Surcharge Amount",
    "message": "Invalid Surcharge amount for the PINless debit transaction."
  },
  {
    "code": "5010",
    "errorName": "Invalid EGC Transaction type",
    "message": "An invalid EGC Transaction type has been supplied with this request."
  },
  {
    "code": "5011",
    "errorName": "Invalid EGC Tender Type",
    "message": "An invalid EGC Tender type has been supplied with this request."
  },
  {
    "code": "5012",
    "errorName": "Invalid Track Data",
    "message": "The track data sent appears to be invalid."
  },
  {
    "code": "5013",
    "errorName": "Invalid Track 2 data",
    "message": "Transaction requires Track2 data to be sent."
  },
  {
    "code": "5014",
    "errorName": "Missing Pin Data",
    "message": "Transaction requires a PIN entry or encrypted PIN device."
  },
  {
    "code": "5015",
    "errorName": "Invalid Voucher Number",
    "message": "The value for the Voucher Number (ssl_voucher_number) field should be 15 digits in length. This value must be numeric."
  },
  {
    "code": "5016",
    "errorName": "Invalid MICR Data",
    "message": "The MICR Data sent appears to be invalid."
  },
  {
    "code": "5017",
    "errorName": "MICR data and image mismatch",
    "message": "The image uploaded doesnd match the MICR data sent for that check."
  },
  {
    "code": "5018",
    "errorName": "Missing MAC value",
    "message": "The MAC value sent appears to be incorrect."
  },
  {
    "code": "5019",
    "errorName": "Minimum Length Error",
    "message": "Minimum Field Character Limit not reached."
  },
  {
    "code": "5020",
    "errorName": "Invalid Field",
    "message": "The Field does not apply to this transaction type."
  },
  {
    "code": "5021",
    "errorName": "Invalid CVV2 Value",
    "message": "The value for the CVV2 (ssl_cvv2cvc2) field should be 3 or 4 digits in length. This value must be numeric."
  },
  {
    "code": "5022",
    "errorName": "Invalid CVV2 Indicator Value",
    "message": "The value for the CVV2 indicator (ssl_cvv2cvc2_indicator) field should be 1 Numeric Character only. Valid values are: 0"
  },
  {
    "code": "5023",
    "errorName": "Invalid card present indicator",
    "message": "Card Present indicator sent is invalid."
  },
  {
    "code": "5024",
    "errorName": "CashBack Amount Invalid",
    "message": "The Cash back amount supplied in the authorization request appears to be invalid."
  },
  {
    "code": "5025",
    "errorName": "Invalid Key pointer",
    "message": "The value for the key pointer (ssl_key_pointer) field should be 1 Character only. Valid value is: T for Triple-DES DUKPT."
  },
  {
    "code": "5030",
    "errorName": "Invalid Billing cycle",
    "message": "The billing cycle specified is not a valid entry."
  },
  {
    "code": "5031",
    "errorName": "Invalid Payment date",
    "message": "The next payment date specified is not a valid entry."
  },
  {
    "code": "5032",
    "errorName": "Invalid installment number",
    "message": "The installment number specified is invalid."
  },
  {
    "code": "5033",
    "errorName": "Invalid recurring ID",
    "message": "The recurring ID is not valid."
  },
  {
    "code": "5034",
    "errorName": "Invalid installment ID",
    "message": "The installment ID is not valid."
  },
  {
    "code": "5035",
    "errorName": "Recurring Limit exceeded",
    "message": "The recurring batch has exceeded the 20"
  },
  {
    "code": "5036",
    "errorName": "Installment payments completed",
    "message": "Installment payments completed."
  },
  {
    "code": "5037",
    "errorName": "Invalid end of month value",
    "message": "Invalid end of month value."
  },
  {
    "code": "5038",
    "errorName": "Invalid half of month value",
    "message": "Invalid half of month value."
  },
  {
    "code": "5039",
    "errorName": "Half of month and next payment date combination mismatch",
    "message": "The half of the month value provided [value] doesnd correspond with the next payment date of [value]."
  },
  {
    "code": "5040",
    "errorName": "Invalid Transaction ID",
    "message": "Transaction ID is invalid for this transaction type."
  },
  {
    "code": "5041",
    "errorName": "Exceeded the 10 minutes window",
    "message": "Unable to void transaction"
  },
  {
    "code": "5042",
    "errorName": "Swipe data is not allowed for this market segment",
    "message": "Swipe data is not allowed for this market segment. Please rekey the card data."
  },
  {
    "code": "5043",
    "errorName": "End Of Month and Next Payment Date combination mismatch",
    "message": "The end of the Month value provided [value] doesnd correspond with the next payment date of [value]."
  },
  {
    "code": "5050",
    "errorName": "Invalid tip",
    "message": "Tip Amount is invalid."
  },
  {
    "code": "5055",
    "errorName": "Missing response file name",
    "message": "The response file name is missing. Please provide a response file name and try again."
  },
  {
    "code": "5056",
    "errorName": "Invalid response file name",
    "message": "The response file name is invalid. Please make sure the response file name doesn't contain any of the characters :\\ / : * ? < > |"
  },
  {
    "code": "5057",
    "errorName": "Missing batch file",
    "message": "The batch file is missing. Please make sure the file exists and try again."
  },
  {
    "code": "5058",
    "errorName": "Invalid batch file name",
    "message": "The batch file name you provided is too long. The file name cannot exceed 30 characters."
  },
  {
    "code": "5059",
    "errorName": "Invalid batch file format",
    "message": "The batch file you uploaded is invalid. Please make sure that the file is properly formatted."
  },
  {
    "code": "5060",
    "errorName": "Invalid batch file extension",
    "message": "The batch file you uploaded has an incorrect extension. Please make sure the file is in either CSV or XML format and try again."
  },
  {
    "code": "5061",
    "errorName": "Import Batch in Progress",
    "message": " retry later"
  },
  {
    "code": "5062",
    "errorName": "File Exceeds Max Number of Transactions",
    "message": "File Exceeds Max Number of Transactions."
  },
  {
    "code": "5063",
    "errorName": "File already imported",
    "message": "File already imported."
  },
  {
    "code": "5064",
    "errorName": "Invalid fields in the batch file",
    "message": "The batch file you uploaded is invalid. Please make sure that the file is properly formatted and file doesnd contain any (fieldname) field or values."
  },
  {
    "code": "5065",
    "errorName": "Invalid response file length",
    "message": "The response file name you provided is too long. The response file name cannot exceed 30 characters."
  },
  {
    "code": "5066",
    "errorName": "Batch Import Limit exceeded",
    "message": "The number of import batch files has exceeded the limit allowed within 24 hours."
  },
  {
    "code": "5067",
    "errorName": "Invalid transaction type present in batch file",
    "message": "The batch file you uploaded is invalid. Please make sure that the file contains valid and supported transaction types. For a complete list of all supported transaction types please consult manual."
  },
  {
    "code": "5068",
    "errorName": "Error processing batch",
    "message": "There was an error in processing your batch. If this issue persist please contact Technical Support at 1-800-377-3962"
  },
  {
    "code": "5069",
    "errorName": "Invalid Batch Import Request",
    "message": "Only Multipart Form Data request are accepted."
  },
  {
    "code": "5070",
    "errorName": "Signature already in System",
    "message": "Transaction ID sent has a signature associated to it in the system."
  },
  {
    "code": "5071",
    "errorName": "Signature format Invalid",
    "message": "All signature images must be BASE64 encoded."
  },
  {
    "code": "5072",
    "errorName": "Signature type Invalid",
    "message": "Signature image type valid values (JPG"
  },
  {
    "code": "5073",
    "errorName": "Signature image exceeds size limitation",
    "message": "Signature image exceeds the 10K size quota."
  },
  {
    "code": "5074",
    "errorName": "Signature is not allowed for this market segment",
    "message": "Signature is not allowed for this market segment."
  },
  {
    "code": "5078",
    "errorName": "The MasterPass wallet is unavailable",
    "message": "The MasterPass wallet is currently unavailable"
  },
  {
    "code": "5079",
    "errorName": "MasterPass is not setup for this terminal",
    "message": "You must setup your terminal to accept MasterPass before sending this value."
  },
  {
    "code": "5080",
    "errorName": "Values for ssl_3dsecure_value and ssl_xid are required",
    "message": "Values for ssl_3dsecure_value and ssl_xid are required."
  },
  {
    "code": "5081",
    "errorName": "Value for ssl_xid is required",
    "message": "Value for ssl_xid is required."
  },
  {
    "code": "5083",
    "errorName": "Invalid DBA name",
    "message": "The DBA name cannot contain special characters and based on the DBA prefix setup for your terminal"
  },
  {
    "code": "5085",
    "errorName": "Invalid Token",
    "message": "The token supplied in the authorization request appears to be invalid."
  },
  {
    "code": "5086",
    "errorName": "Invalid card information",
    "message": "The card information supplied in the authorization request appears to be invalid. You cannot provide both token and card number for processing."
  },
  {
    "code": "5087",
    "errorName": "Transaction currency is not allowed for this terminal",
    "message": "Transaction currency is not allowed for this terminal. You must be setup with Multi-Currency to specify a transaction currency."
  },
  {
    "code": "5088",
    "errorName": "Transaction currency is not allowed for this Card Type",
    "message": "In order to process Multi-Currency you must use either a Visa or a MasterCard"
  },
  {
    "code": "5089",
    "errorName": "Invalid Transaction Currency",
    "message": "The transaction currency sent is not correct. Please provide the correct 3-digit ISO code."
  },
  {
    "code": "5090",
    "errorName": "Invalid BIN Override Value",
    "message": "Invalid BIN Override Value."
  },
  {
    "code": "5091",
    "errorName": "Invalid Amount",
    "message": "The amount exceeds the original transaction amount."
  },
  {
    "code": "5092",
    "errorName": "Invalid country code",
    "message": "Invalid country code."
  },
  {
    "code": "5093",
    "errorName": "Transaction Time Exceeded",
    "message": "Transaction has timed out"
  },
  {
    "code": "5094",
    "errorName": "Invalid Travel information",
    "message": "The travel departure or completion dates specified are not valid. Dates should be formatted as MM/DD/YYYY and cannot be in the past."
  },
  {
    "code": "5095",
    "errorName": "Invalid Search Criteria",
    "message": "Too many search criteria were entered for this query. Modify the search and try again."
  },
  {
    "code": "5096",
    "errorName": "Invalid Transaction Currency",
    "message": "The currency sent doesnd match the original transaction currency."
  },
  {
    "code": "5097",
    "errorName": "Missing Travel information",
    "message": "The Departure Date and the Completion Date must be sent together."
  },
  {
    "code": "5098",
    "errorName": "Invalid Transaction Currency",
    "message": "The transaction currency sent is not supported for this terminal."
  },
  {
    "code": "5099",
    "errorName": "Invalid Search Date",
    "message": "Search dates must be formatted as MM/DD/YYYY or MM/DD/YYYY hh:mm:ss AM/PM"
  },
  {
    "code": "5100",
    "errorName": "Account Closed",
    "message": "This account has been closed by Account Updater."
  },
  {
    "code": "5101",
    "errorName": "MICR/Image Data is not allowed for this terminal",
    "message": "MICR read or check image is not allowed for this terminal. Your terminal must be setup for ECS Paper Check."
  },
  {
    "code": "5102",
    "errorName": "Keyed Check is not allowed for this terminal",
    "message": "Key entered check is not allowed for this terminal. Your terminal must be setup for ACH ECheck."
  },
  {
    "code": "5103",
    "errorName": "Invalid bank account type value",
    "message": "The value for the check type (ssl_bank_account_type) field should be 1 Numeric Character only. Valid values are: 0 for Personal"
  },
  {
    "code": "5104",
    "errorName": "Invalid agreement indicator value",
    "message": "The value for the agreement indicator (ssl_agree) field should be 1 Numeric Character only. Valid values are: 1 for Agree"
  },
  {
    "code": "5105",
    "errorName": "Invalid Bank Account Number",
    "message": "The bank account number supplied in the authorization request appears to be invalid. The bank account number must numeric and up to 16 characters long."
  },
  {
    "code": "5106",
    "errorName": "Invalid Bank Routing Number",
    "message": "The bank routing number supplied in the authorization request appears to be invalid. The bank routing number must be numeric fixed value of 9 characters."
  },
  {
    "code": "5107",
    "errorName": "Invalid Check Number",
    "message": "The Check number supplied in the authorization request appears to be invalid. The check number must be numeric."
  },
  {
    "code": "5108",
    "errorName": "Missing check holder information",
    "message": "The check information supplied in the request appears to be missing. You must provide first and last names for a personal check or a company name for a business check."
  },
  {
    "code": "5121",
    "errorName": "Invalid Mobile indicator",
    "message": "The mobile indicator appears to be missing or invalid."
  },
  {
    "code": "5122",
    "errorName": "Invalid Merchant IP Address",
    "message": "The IP Address appears to be missing or invalid."
  },
  {
    "code": "5123",
    "errorName": "Email is not setup for this terminal",
    "message": "Email is not setup for this terminal. You must enable the email options under the email setup form."
  },
  {
    "code": "5125",
    "errorName": "Invalid POS",
    "message": " entry mode and card data combination"
  },
  {
    "code": "5126",
    "errorName": "Invalid mobile source",
    "message": "Invalid mobile source."
  },
  {
    "code": "5130",
    "errorName": "EMV processing is not allowed for this card type.",
    "message": "You can process this card type swiped or keyed."
  },
  {
    "code": "5131",
    "errorName": "Swiped without fallback is not allowed",
    "message": "Chip read must fail before fallback to swipe is allowed. You must attempt to insert card first."
  },
  {
    "code": "5132",
    "errorName": "Signature is not allowed for this card",
    "message": "Card verification method for this card does not require a signature"
  },
  {
    "code": "5133",
    "errorName": "Invalid Card Type.",
    "message": "Transaction not supported with this card type."
  },
  {
    "code": "5135",
    "errorName": "Invalid Search Transaction Type",
    "message": "Transaction type provided in the request is not valid. Valid values are: SALE"
  },
  {
    "code": "5136",
    "errorName": "Invalid Search Amount",
    "message": "The amount supplied in the search appears to be invalid. All amounts must be formatted correctly and max amount must be greater than the min amount if supplied."
  },
  {
    "code": "5137",
    "errorName": "Invalid Search Card Type",
    "message": "The card type supplied in the search appears to be invalid. Valid values are: CREDITCARD"
  },
  {
    "code": "5138",
    "errorName": "Invalid Search Card Brand",
    "message": "The card brand supplied in the search appears to be invalid. Valid values are: VISA"
  },
  {
    "code": "6038",
    "errorName": "System is Temporarily Unavailable",
    "message": "It appears that the system is temporarily unavailable. Please try your transaction again in a few minutes or contact the merchant you are trying to order from for further assistance. We apologize for this inconvenience."
  },
  {
    "code": "6042",
    "errorName": "Invalid Request Format",
    "message": "XML request is not well-formed or request is incomplete."
  },
  {
    "code": "7005",
    "errorName": "Invalid Token Request",
    "message": "The information supplied in the request is invalid. A token cannot be added unless one has been requested."
  },
  {
    "code": "7022",
    "errorName": "Invalid Service Fee Transaction",
    "message": "Service Fee transaction is not allowed."
  },
  {
    "code": "7028",
    "errorName": "Invalid Settlement Batch ID",
    "message": "Settlement Batch ID has invalid size or format."
  },
  {
    "code": "7029",
    "errorName": "Duplicate Settlement Batch ID",
    "message": "Batch ID number already exists."
  },
  {
    "code": "3000",
    "errorName": "Gateway not responding",
    "message": "Error"
  }
]