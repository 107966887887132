<template>
	<div class="flex justify-center">
		<div class="container">
			<div class="headline-3">Transactions</div>
			<!-- Main Container Row -->
			<div class="row my-3">
				<!-- Main Content Left Area -->
				<div class="col-12">
					<template>
						<v-card>
							<v-card-title class="bg-primary mb-0">
								<div class="col-6">

								</div>
								<div class="col-6 text-end">
									<vue-json-to-csv
										:json-data="CSVData"
										:labels="{ 
											id: { title: 'Id' }, 
											date: { title: 'Date' }, 
											card_scheme: { title: 'Card Brand' },
											card_type: { title: 'Card Type'},
											customer_name: { title: 'Patient Name'},
											invoice: { title: 'Invoice Number'},
											customer_id: { title: 'Customer ID'},
											customer_email: { title: 'Patient Email'},
											last_four: { title: 'CC Last 4' },
											amount: {title: 'Amount'},
											result: { title: 'Response'} }"
										:csv-title="'Transactions ' + new Date().toISOString().substr(0,10)"
										@success="val => handleSuccess(val)"
										@error="val => handleError(val)"
									>
										<v-btn>
											Download
											<v-icon class="ml-3">
												mdi-cloud-download
											</v-icon>
										</v-btn>
										
									</vue-json-to-csv>
									
								</div>
							</v-card-title>
							<v-data-table
								class="customHeader"
								:headers="headers"
								:items="mountedTransactions.results"
								:loading="loading"
								sort-by="date_added"
								:sort-desc="true"
								:items-per-page="itemsPerPage"
								:page.sync="page"
								:server-items-length="mountedTransactions.count"
								disable-sort
								hide-default-footer
								show-expand
								:single-expand="true"
							>

							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<v-row class="p-3">
										<v-row>
											<v-col cols="4">
												Time: {{item.jsonResult.ssl_txn_time}}
											</v-col>
											<v-col cols="4">
												Approval Code: {{item.approval_code || "NOT AVAILABLE"}}
											</v-col>
											<v-col cols="4">
												Card Number: {{item.jsonResult.ssl_card_number}}
											</v-col>
										</v-row>
										<v-row justify="space-between">
											<v-col cols="6">
												Transaction ID: {{item.jsonResult.ssl_txn_id}}
											</v-col>
											<v-col cols="6" class="text-center">
												Transaction Type: {{item.jsonResult.ssl_transaction_type}}
											</v-col>
										</v-row>		
									</v-row>			
								</td>
							</template>
							<!-- Header Search Slots -->
							<template v-slot:header.customer.last_name>
								<div class="row flex-wrap justify-center mb-1">
									<div class="col-12 mb-1">
										<div class="text-base">
											Patient Name
										</div>
									</div>
									<div class="col-12">
										<v-text-field 
											outlined
											placeholder="search last name"
											dense
											hide-details
											v-model="nameSearch"/>
									</div>
								</div>
							</template>
							<template v-slot:header.customer.customer_id>
								<div class="row flex-wrap justify-center mb-1">
									<div class="col-12 mb-1">
										<div class="text-base">
											Account #
										</div>
									</div>
									<div class="col-12">
										<v-text-field 
											outlined
											placeholder="search"
											dense
											hide-details
											v-model="accountNumberSearch"/>
									</div>
								</div>
							</template>
							<template v-slot:header.last_four>
								<div class="row flex-wrap justify-center mb-1">
									<div class="col-12 mb-1">
										<div class="text-base">
											Last 4
										</div>
									</div>
									<div class="col-12">
										<v-text-field 
											outlined
											placeholder="search"
											dense
											hide-details
											v-model="last4Search"/>
									</div>
								</div>
							</template>
							<!-- Top Slot -->
							<template v-slot:top>
								<div class="row grey lighten-2 p-4">
									<div class="row">
										<div class="col-4">
											<div class="field-title">
												Date Range
											</div>
											<div class="field-content">
												<template>
													<v-row>
														<v-col
															cols="12"
														>
															<v-menu
																ref="menu"
																v-model="menu"
																:close-on-content-click="false"
																:return-value.sync="dates"
																transition="scale-transition"
																offset-y
																min-width="auto"
															>
																<template v-slot:activator="{ on, attrs }">
																	<v-text-field
																		outlined
																		color="black"
																		background-color="white"
																		v-model="dates"
																		prepend-icon="mdi-calendar"
																		readonly
																		v-bind="attrs"
																		v-on="on"
																		hide-details
																	></v-text-field>
																</template>
																<v-date-picker
																	v-model="dates"
																	range
																	no-title
																	scrollable
																>
																	<v-spacer></v-spacer>
																	<v-btn
																		text
																		color="primary"
																		@click="menu = false"
																	>
																		Cancel
																	</v-btn>
																	<v-btn
																		text
																		color="primary"
																		@click="$refs.menu.save(dates)"
																	>
																		OK
																	</v-btn>
																</v-date-picker>
															</v-menu>

														</v-col>
													</v-row>
													
												</template>

											</div>
										</div>
										<div class="col-4">
											<div class="field-title">
												Response
											</div>
											<div class="field-content">
												<v-select
													v-model="selectedResponse"
													outlined
													hide-details
													background-color="white"
													:items="responseList"
													item-text="name"
													item-value="id"
													class="mx-4"
												></v-select>
											</div>
										</div>
										<div class="col-4 flex items-center mt-6 justify-center">
											<v-btn
												@click="filterTable"
												color="secondary">
												Filter Data
											</v-btn>
										</div>
									</div>

								</div>
								
							</template>

							<template v-slot:item.result="{ item }">
								<div class="flex justify-center">
									<v-chip
										label
										:color="item.result === '0' ? 'success': 'error'"
									>
										{{ item.result === '0'?'Approved':'Declined' }}
									</v-chip>
								</div>
							</template>
							<template v-slot:item.date_added="{ item }">
								<div class="flex justify-center">
									{{ item.date_added | moment('MMM D, YYYY') }}
								</div>
							</template>
							<template v-slot:item.card_type="{ item }">
								<div class="flex justify-center">
									{{ item.card_type | capitalize }}
								</div>
							</template> 
					
							<template v-slot:item.jsonRequest="{ item }">
								<div v-if="jsonRequest.bin_info" class="flex justify-center">
									{{ item.jsonRequest.bin_info.scheme | capitalize }}
								</div>
							</template>
							<!-- Custoemr Name -->
							<template v-slot:item.customer.last_name="{ item }">
								<div class="flex justify-center">
									{{ item.customer.first_name + " " + item.customer.last_name }}
								</div>
							</template>
					
				
							<template v-slot:item.jsonRequest="{ item }">
								<div v-if="jsonRequest.bin_info == undefined" class="flex justify-center">
									Not Available
								</div>
							</template>

							<template v-slot:footer="{ props }">
								<div class="row justify-end">
									<div class="mx-4 text-sm">
										Rows per page: {{ props.pagination.itemsPerPage }}
									</div>
									<div class="mx-4 text-sm">
										{{ props.pagination.pageStart +1 }} - {{ props.pagination.pageStop }} of {{ props.pagination.itemsLength }}
									</div>
								</div>
							</template>
				
							</v-data-table>

							<!-- Pagination -->
							<v-pagination
								v-model="page"
								:length="pageLength"
								@input="pageSelect"
								@next="nextPage"
								@previous="previousPage"
								:total-visible="7"
							></v-pagination>

							

						</v-card>
					</template>
				</div>
			

				
				<div class="row my-5">
					<div class="col-12">
						<v-btn
							color='error'
							@click="testButton">
							Test
						</v-btn>
					</div>
				</div>
	

			</div>
			<!-- END Main Container Row -->
			<!--Bottom Row -->

		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

var date = new Date()
var year = new Date().getFullYear()
// console.log('year', year)
var month = new Date().getMonth()
// console.log('month', new Date(String(year)+'-'+String(month+1)+'-02').toLocaleDateString())

import moment from 'moment'

export default {
  name: 'merchantTransactions',
  components: {
  },
  mixins: [

  ],
  props: {

  },
  data () {
    return {
			// Main Data
			loading: false,
			CSVData: [],
			mountedTransactions: [],
			isSelected: false,

			//Datatable
			page: 1,
			itemsPerPage: 50,
			//Search
			nameSearch: '',
			last4Search: '',
			accountNumberSearch: '',
			headers: [
				{ text: 'id', align:'center', value: 'id' },
				{ text: 'Date', align:'center', value: 'date_added', width:'150' },
				{ text: 'Brand', align:'center', value: 'card_scheme' },
				{ text: 'Card Type', align:'center', value: 'card_type' },
				{ text: 'Patient Name', align:'center', value: 'customer.last_name' },
				{ text: 'Patient #', align:'center', value: 'customer.customer_id' },
				{ text: 'Last 4', align:'center', value: 'last_four' },
				{ text: 'Amount', align:'center', value: 'jsonResult.ssl_amount' },
				{ text: 'Response', align:'center', value: 'result' },
			],

			selectedResponse: null,
			responseList: [
				{name: 'Declined', id: '1'},
				{name: 'Approved', id: '0'},
				{name: 'All', id:  null},
			],

			//Dates Picker
			menu: false,
			dates: [new Date(String(year)+'-'+String(month+1)+'-02').toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
      
    }
  },
  methods: {
    testButton () {
			console.log('this.GET_TRANSACTION_LIST', this.GET_TRANSACTION_LIST)
			console.log("this.GET_SELECTED_TRANSACTION_LIST", this.GET_SELECTED_TRANSACTION_LIST);
			console.log('this.GET_SELECTED_MERCHANT_PROFILE', this.GET_SELECTED_MERCHANT_PROFILE)
		},
		//Used only when paginating fro the backend
		// updatePage(evt) {
		// 	console.log("updatePage evt", evt)
		// },
		// sortBy(evt) {
		// 	console.log("sortBy evt", evt)
		// },
		// options(evt) {
		// 	console.log("options evt", evt)
		// },
		handleSuccess(evt) {
			// console.log("handleSuccess evt", evt)
		},
		handleError(evt) {
			// console.log("handleError evt", evt)
		},
		parseCSVData(data) {
			// console.log("parseCSVData data", data);
			this.CSVData = []
			for(let key in data.results) {
				var newObj = {
					id:data.results[key].id,
					date:data.results[key].date_added,
					card_scheme:data.results[key].card_scheme,
					card_type:data.results[key].card_type,
					customer_name:data.results[key].customer.first_name + " " + data.results[key].customer.last_name,
					invoice:data.results[key].customer.invoice_number,
					customer_id:data.results[key].customer.customer_id,
					customer_email:data.results[key].customer.email,
					last_four:String(data.results[key].last_four),
					amount:data.results[key].jsonResult.ssl_amount,
					result:data.results[key].result== 0 ? 'Approved': 'Declined',
				}
				this.CSVData.push(newObj)
			}
		},
		async filterTable() {
			// console.log('this.nameSearch', this.nameSearch)
			// console.log("filter table executed");
			// console.log("this.GET_SELECTED_MERCHANT_PROFILE", this.GET_SELECTED_MERCHANT_PROFILE);
			// console.log("this.GET_MERCHANT_PROFILE", this.GET_MERCHANT_PROFILE);
			let mid = this.isSelected ? this.GET_SELECTED_MERCHANT_PROFILE.id: this.GET_MERCHANT_PROFILE.id;
			
			let response = await this.$store.dispatch('FILTERTransactionsByFilterFields', {
				result: this.selectedResponse, 
				id: mid,
				page:1, start:this.dates[0] +'T00:00:00Z', 
				end: this.dates[1] +'T23:59:00Z',
				last_name: this.nameSearch,
				last_four: this.last4Search,
				acct_no: this.accountNumberSearch,
				})
			if(response) {
				this.parseCSVData(response)
				this.mountedTransactions = response
			}
		},
		async pageSelect(evt) {
			// console.log("pageSelect evt", evt)
			let mid = Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0? this.GET_SELECTED_MERCHANT_PROFILE.id: this.GET_MERCHANT_PROFILE.id;
			this.loading = true
			let response = await this.$store.dispatch('FILTERTransactionsByFilterFields', {result: this.selectedResponse, id: mid, page:evt, start:this.dates[0] +'T00:00:00Z', end: new Date().toISOString()})
			if(response) {
				this.parseCSVData(response)
				this.mountedTransactions = response
			}
			this.loading = false
		},
		async nextPage(evt) {
			// console.log("nextPage evt", evt)
			this.loading = true
			if(Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0) {
				if(this.GET_SELECTED_TRANSACTION_LIST.next) {
					var endpoint = this.GET_SELECTED_TRANSACTION_LIST.next.split('9010')[1]
					let response = await this.$store.dispatch('NextPreviousTransactions', endpoint)
					if(response) {
						this.parseCSVData(response)
						this.mountedTransactions = response
					}
				}
			} else {
				if(this.GET_TRANSACTION_LIST.next) {
					var endpoint = this.GET_TRANSACTION_LIST.next.split('9010')[1]
					let response = await this.$store.dispatch('NextPreviousTransactions', endpoint)
					if(response) {
						this.parseCSVData(response)
						this.mountedTransactions = response
					}
				}
			}
			this.loading = false
		},
		async previousPage(evt) {
			// console.log("previousPage evt", evt)
			this.loading = true
			if(Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0) {
				if(this.GET_SELECTED_TRANSACTION_LIST.previous) {
					var endpoint = this.GET_SELECTED_TRANSACTION_LIST.previous.split('9010')[1]
					let response = await this.$store.dispatch('NextPreviousTransactions', endpoint)
					if(response) {
						this.parseCSVData(response)
						this.mountedTransactions = response
					}
				}
			} else {
				if(this.GET_TRANSACTION_LIST.previous) {
					var endpoint = this.GET_TRANSACTION_LIST.previous.split('9010')[1]
					let response = await this.$store.dispatch('NextPreviousTransactions', endpoint)
					if(response) {
						this.parseCSVData(response)
						this.mountedTransactions = response
					}
				}
			}
			
			this.loading = false
		},
		async searchDB() {
			// console.log("searchDB")
			this.loading = true
			if(this.isSelected) {
				if(this.GET_SELECTED_TRANSACTION_LIST.next) {
					var endpoint = this.GET_SELECTED_TRANSACTION_LIST.next.split('9010')[1]
					let response = await this.$store.dispatch('NextPreviousTransactions', endpoint)
					if(response) {
						this.parseCSVData(response)
						this.mountedTransactions = response
					}
				}
			} else {
				if(this.GET_TRANSACTION_LIST.next) {
					var endpoint = this.GET_TRANSACTION_LIST.next.split('9010')[1]
					let response = await this.$store.dispatch('NextPreviousTransactions', endpoint)
					if(response) {
						this.parseCSVData(response)
						this.mountedTransactions = response
					}
				}
			}
			this.loading = false
		},
		mountData() {
			// console.log("this.GET_SELECTED_MERCHANT_PROFILE", this.GET_SELECTED_MERCHANT_PROFILE);
			if(Object.keys(this.GET_SELECTED_MERCHANT_PROFILE).length != 0) {
				this.isSelected = true
				// console.log("list longer than 0 mounted");
				this.mountedTransactions = this.GET_SELECTED_TRANSACTION_LIST
			}else {
				this.isSelected = false
				// console.log("using transaction list only mounted");
				this.mountedTransactions = this.GET_TRANSACTION_LIST
			}
					this.parseCSVData(this.mountedTransactions)
		}
  },
  computed: {
    ...mapState([]),
		...mapGetters(["GET_TRANSACTION_LIST", "GET_MERCHANT_PROFILE", "GET_SELECTED_TRANSACTION_LIST", "GET_SELECTED_MERCHANT_PROFILE"]),
		getLabels(evt) {
			// console.log('evt', evt)
			return {
			}
		},
		pageLength() {
			if(this.mountedTransactions.count >0) {
				return Math.ceil(this.mountedTransactions.count / this.itemsPerPage)
			} 
			return 0
		},	
  },
  mounted () {
		// console.log('this.$route.name', this.$route.name)
		this.mountData()
	},
	created() {

	}
}
</script>
<style scoped lang="scss">


</style>
