<template>
	<div>
		<template>
			<div class="row">
				<div class="col-12">
					<v-form
						ref="formValidationRef"
						v-model="amountValidation">

						<v-text-field
							outlined
							@change="form.ssl_amount = $event"
							:value="form.ssl_amount | dollar"
							label="USD"
							v-model="form.ssl_amount"
							:rules="amountRules">
					</v-text-field>
					</v-form>
				</div>
			</div>

		</template>

		<div class="divider mb-6"></div>

		<template>
			<div class="row">
				<div class="col-6">
					<div class="row">
						Subtotal
					</div>
	
				</div>
				<div class="col-6">
					<div class="row justify-end">
						{{ form.ssl_amount | dollar }}
					</div>

				</div>
			</div>
			<div class="border-1 border-gray-300 border-opacity-5 w-full m-2"></div>
			<div class="row">
				<div class="col-6">
					Total
				</div>
				<div class="col-6 text-right">
					<span class="mx-1">
						<span class="text-xs font-semibold">
							USD
						</span>
						</span>
					<span>{{ form.ssl_amount | dollar }}</span>
				</div>
			</div>
		</template>

		<!-- <div class="row">
			<div class="col-12">
				<v-btn
					@click="testButton">
					Test

				</v-btn>
			</div>
		</div> -->
		<div class="row mt-24">
			<div class="col-12 flex justify-center">
				<v-img
					max-height="200"
					max-width="200"
					src="@/assets/static/Blue SSL Logo.png"
				></v-img>
			</div>
		</div>
		

	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

//Mixins
import { FormMixins } from "@/mixins/form-mixins"

export default {
  name: 'checkoutListTableComponent',
  components: {

  },
  mixins: [
		FormMixins,
  ],
  props: {
		form: {
			type: Object, 
			required: true
		}
  },
  data () {
    return {
			// Main Data
			errorMsg: null,
			amountValidation: false

   

    }
  },
  methods: {
    testButton() {
			console.log("this.amount", this.amount)
		},
		checkCoupon() {
			if(this.couponCode != null) {
				this.errorMsg = "Sorry, this code does not work"
			} else {
				this.errorMsg = "Please enter a coupon code"
			}
		},
		validate() {
			return this.$refs.formValidationRef.validate()
		}


  },
  computed: {
    ...mapState([]),
		...mapGetters([]),


	},
	created() {

	},
  mounted () {


		

		
	},

}
</script>

<style scoped lang="scss">

</style>
