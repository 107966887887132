<template>
	<v-card>
		<v-card-title class="bg-primary text-white">
			<v-row class="p-2">
				<v-col cols=9>
					<div class="title">
						<slot name="title"></slot>
					</div>
				</v-col>
				<!-- <div class="col-3 text-right">
					<div class="offset-header-buttons">
						<v-btn class="mx-2" fab dark color="accent">
							<v-icon dark>mdi-upload</v-icon>
						</v-btn>
					</div>
				</div> -->
			</v-row>

			<div>

			</div>
		</v-card-title>
		<!-- Form -->
		<template>
			<div class="p-5">
				<v-form
					ref="companyFormRef"
					v-model="validForm"
				> 
					<div class="header">
						Linked Accounts
					</div>

					<slot name="linked-accounts" />

					<div class="row">
						<div class="header">
							Merchant Name
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<v-text-field
								label="Legal Name *"
								:rules="requiredRule"
								v-model="companyForm.legal_name">
							</v-text-field>
						</div>
						<div class="col-6">
							<v-text-field
								:rules="requiredRule"
								label="DBA Name *"
								v-model="companyForm.dba_name">
							</v-text-field>
						</div>
						
					</div>
					<div class="row">
						<div class="col-6">
							<v-text-field
								label="Merchant ID *"
								:rules="merchantIdRule"
								v-mask="'##########'"
								v-model="companyForm.merchant_id">
							</v-text-field>
						</div>
						<div class="col-6">
							
							<v-text-field
								label="Domain *"
								:rules="[rules.required, rules.lettersOnly]"
								v-model="companyForm.domain">
							</v-text-field>
						</div>
					</div>
					<div class="row">
						<div class="header">
							Primary Contact Information
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<v-text-field
								v-model="companyForm.primary_first_name"
								:rules="nameRules"
								label="Primary First Name *"
							></v-text-field>
						</div>
						<div class="col-6">
							<v-text-field
								v-model="companyForm.primary_last_name"
								:rules="nameRules"
								label="Primary Last Name *"
							></v-text-field>
						</div>
					</div>
					
					<div class="row">
						<div class="col-6">
							<v-text-field
								v-model="primary_phone"
								label="Primary Phone *"
								:rules="phoneRules"
								v-mask="'(###) ###-####'"
							></v-text-field>
						</div>
						<div class="col-6">
							<v-text-field
								v-model="primary_fax"
								:rules="faxRules"
								v-mask="'(###) ###-####'"
								label="Primary Fax"
							></v-text-field>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<v-text-field
								v-model="companyForm.primary_email"
								:rules="emailRules"
								label="Primary Email *"
							></v-text-field>
						</div>
					</div>

					<div class="row">
						<div class="header mt-5">
							Primary Address Details
						</div>
					</div>
					<div class="row">
						<div class="col-8">
							<v-text-field
								v-model="companyForm.primary_address"
								:rules="requiredRule"
								label="Address *"
							></v-text-field>
						</div>
						<div class="col-4">
							<v-text-field
								v-model="companyForm.primary_address2"
								label="Address 2"
							></v-text-field>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<v-text-field
								v-model="companyForm.primary_city"
								:rules="requiredRule"
								label="City *"
							></v-text-field>
						</div>
						<div class="col-4">
							<v-select
								v-model="companyForm.primary_state"
								:items="states"
								item-text="state"
								item-value="abbr"
								persistent-hint
								label="State *"
							></v-select>
							
							<!-- <v-text-field
								v-model="stateNames"
								v-mask="'AA'"
								:rules="requiredRule"
								label="State *"
							></v-text-field> -->
						</div>
						<div class="col-4">
							<v-text-field
								v-model="companyForm.primary_zip_code"
								:rules="[rules.required, rules.zipRule]"
								v-mask="'#####'"
								label="Zip Code *"
							></v-text-field>
						</div>
					</div>


					<div class="row">
						<div class="header mt-5">
							Manage Account
						</div>
					</div>
					<div class="row justify-center">
						<div class="col-4">
							<v-switch
								v-model="companyForm.is_locked"
								label="Lock Account?"
							></v-switch>
						</div>
						<div class="col-6">
							<v-switch
								v-model="companyForm.is_active"
								label="Is Account Active?"
							></v-switch>
						</div>
					</div>
		
					<div class="row">
						<div class="header">
							Account Notes
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<v-textarea
							label="Notes"
								name="Account Notes"
								v-model="companyForm.notes"
							></v-textarea>
						</div>
					</div>
					<div class="row">
						<div class="header">
							Logo Image
						</div>
					</div>

					<slot name="profile-info" />

					<!-- <div v-if="companyForm.id != null">
						<div class="row">
							<div class="header">
								Users
							</div>
						</div>
						<div class="row justify-end offset-user-button text-right">
							<v-btn class="mx-2" fab dark color="accent">
								<v-icon dark>mdi-plus-thick</v-icon>
							</v-btn>	
						</div>
						<div class="row offset-user-button">
							<div class="col-12">
								<v-chip
									v-for="user in GET_MERCHANT_EMPLOYEES_LIST"
									:key="user.id"
									class="ma-2"
									color="teal"
									text-color="black"
								>
									<div v-if="user.user_obj.is_active">
										<v-avatar v-if="!user.profile_img" left>
											<v-img
												height="20"
												width="20"
												src="@/assets/static/Male-Profile170X150.png"
											></v-img>
										</v-avatar>
										<v-avatar v-else left>
											<v-img
												height="20"
												width="20"
												:src="user.profile_img"
											></v-img>
										</v-avatar>
										{{ user.user_obj.full_name }}
									</div>
									
								</v-chip>

							</div>
						</div>
					</div> -->

					<v-row v-if="$can('view', 'admin')" class="justify-end">
						<v-col cols="3" class="text-right" v-if="$can('view', 'superuser')">
							<v-btn
								color="warning"
								class="mr-4"
								@click="testButton"
							>
								Testing
							</v-btn>
						</v-col>
						<v-col cols="3" class="text-right">
							<v-btn
								color="error"
								class="mr-4"
								@click="resetForm"
							>
								Reset
							</v-btn>
						</v-col>
						<v-col cols="3" class="text-right">
							<v-btn
								color="success"
								class="mr-4"
								@click="sendToParent"
							>
								Submit
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
				<div class="my-5 py-6">

				</div>
			</div>

		</template>
		<!-- END Form -->
	</v-card>
</template>

<script>
"use-strict";
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

import { stateNames } from "@/js/states.js"

export default {
  name: 'baseCompanyFormComponent',
  components: {
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			showPW: false,
			confirmPassword: "",
			validForm: false,
			states: stateNames,
			

    }
  },
  methods: {
    testButton () {
			// console.log('this.$refs.companyFormRef.validate()', this.$refs.companyFormRef.validate())
			// this.$refs.companyFormRef.validate()
			// console.log('this.validForm', this.validForm)
			// console.log("companyForm", this.companyForm);
		},
		async sendToParent() {
			// console.log("this.companyForm before", this.companyForm);
			const validform = this.$refs.companyFormRef.validate()
			//If form Id == selected_merchant.id then perform update PATCH
			// console.log('this.GET_SELECTED_MERCHANT_PROFILE', this.GET_SELECTED_MERCHANT_PROFILE)

			// console.log('validform', validform)

			if(this.companyForm.id) {
				//Perform PATCH
				if(validform) {
					this.primary_phone ? this.companyForm.primary_phone = await this.parsePhoneNumbers(this.primary_phone): null
					this.primary_fax ? this.companyForm.primary_fax = await this.parsePhoneNumbers(this.primary_fax): null

					// console.log("373 this.primary_phone", this.primary_phone);
					// console.log("373 this.primary_fax", this.primary_fax);
					// console.log("373 this.companyForm", this.companyForm);
					this.$emit('CompanyData', this.companyForm)
					// console.log("this.$parent", this.$parent);
					this.$parent.$parent.updateMerchant();
				}	

			} else {
				//Create New Merchant

				if(validform) {
					this.primary_phone ? this.companyForm.primary_phone = await this.parsePhoneNumbers(this.primary_phone): null
					this.primary_fax ? this.companyForm.primary_fax = await this.parsePhoneNumbers(this.primary_fax): null

					this.$emit('CompanyData', this.companyForm)
					// console.log("this.$parent", this.$parent);
					this.$parent.$parent.createMerchant();
				}	
			}
		},
		parsePhoneNumbers(itm) {
			// console.log("parsePhoneNumbers", itm);
			return itm.replace(/\D/g,'')
		},
		async updateForm(evt) { // From the Datatable selection
			//Get Object from Store
			var getMerchantObj = await this.$store.dispatch("GETSelectedMerchantById", {id: evt.id});
			// console.group('getMerchantObj', getMerchantObj);

			//Get Merchant Users
			// let employees = await this.$store.dispatch("GETMerchantEmployeeList", {id: getMerchantObj.id})
			// console.log("employeeList", employees)
			this.$forceUpdate()

			// console.log("getMerchantObj", getMerchantObj);

			this.primary_phone = getMerchantObj.primary_phone ? await this.parsePhoneNumber(getMerchantObj.primary_phone): null
			this.primary_fax = getMerchantObj.primary_fax ? await this.parsePhoneNumber(getMerchantObj.primary_fax) : null

			this.shipping_phone = getMerchantObj.shipping_phone ? await this.parsePhoneNumber(getMerchantObj.shipping_phone): null
			this.shipping_fax = getMerchantObj.shipping_fax ? await this.parsePhoneNumber(getMerchantObj.shipping_fax): null

			//Map to existing form
			for(let key in this.companyForm) {
				// console.log('key', key)
				this.companyForm[key] = getMerchantObj[key]
			}

			// console.log('this.companyForm, ', this.companyForm)
		},
		resetForm() {
			this.resetFormData("baseCompanyFormRef")

			this.$refs.companyFormRef.reset()
			this.$refs.companyFormRef.resetValidation()

			this.$emit("clearCheckbox")

		},
		parsePhoneNumber(evt) {
			// console.log("Parsing phone numbers");
			return new Promise((resolve, reject) => {
				// console.log('evt', evt)
				try {
					if(evt) {
						var areaCode, prefix, ending
						areaCode = evt.slice(0,3)
						prefix = evt.slice(3,6)
						ending = evt.slice(6)
						resolve("(" + areaCode + ")" + " " + prefix + "-" + ending)
					} else {
						return null
					}
					
				} catch (error) {
					// console.log('error', error)
				}
			})
		}
		

  },
  computed: {
    ...mapState(["Merchants"]),
		...mapGetters(["GET_SELECTED_MERCHANT_PROFILE", "GET_MERCHANT_EMPLOYEES_LIST"]),

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">
.offset-user-button {
	position:relative;
	right: 0;
	top: -40px;
}
</style>
