import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import { router } from '@/routes'
import apiRoutes from '@/js/api-routes'

Vue.use(Vuex)

export const Auth = {
  namespace: true,
  name: 'auth',
  state: {
		token: null,
		foundLocalStorageUser: null,
    userLogin: null,
		userProfile: {},
		fullUserProfile: {},
		isAuthenticated: false,
		userLevel: null,
		preFetchSuccess: false
  },
  mutations: {
		CLEAR_USER_PROFILE(state, payload) {
			state.token= null
			state.foundLocalStorageUser= null
			state.userLogin= null
			state.userProfile= {}
			state.fullUserProfile= {}
			state.isAuthenticated= false
			state.userLevel= null
			state.preFetchSuccess= false
		},
		LOG_OUT_USER (state, payload) {
      // console.log('REMOVE_TOKEN Payload')
      localStorage.removeItem('expiration')
			localStorage.removeItem('user')
    },
		SET_PREFETCH_SUCCESS(state, payload) {
			state.preFetchSuccess = payload
		},
    SET_USER_LOGIN (state, payload) {
      // console.log('SET_USER_LOGIN payload', payload)
      state.userLogin = payload
      state.isAuthenticated = true
      localStorage.setItem('user', JSON.stringify(payload))
			localStorage.setItem('expiration', 604800)
			state.token = 'Token '+ payload.token
    },
    SET_USER_PROFILE (state, payload) {
			Vue.set(state, 'userProfile', payload)
			// console.log("SET_USER_PROFILE", state.userProfile)
		},
		SET_FULL_PROFILE(state, payload) {
			Vue.set(state, 'fullUserProfile', payload)
		},
		SET_USER_LEVEL(state, payload) {
			if(state.userProfile.is_superuser) {
				state.userLevel = 1
			} else if(state.userProfile.is_merchant) {
				state.userLevel = 2
			}
		},
		FIND_USER_IN_STORAGE ( state, payload) {
			// console.log('FIND_USER_IN_STORAGE')
				var user = JSON.parse(localStorage.getItem('user'))
				// console.log('FIND_USER_IN_STORAGE user', user)
      if (user != null) {
				// console.log('FIND_USER_IN_STORAGE state.userProfile', user)
				state.foundLocalStorageUser = user
      } 
				
		},
  },
  actions: {
    async preFetchLogin ({ dispatch, commit, state, rootState }, userData) {
			return new Promise(async (resolve, reject) => {
				// rootState.Notifications.isLoadPanelVisible = true
				// console.log("preFetchLogin", userData)

				let AUTH_TOKEN = JSON.parse(localStorage.getItem('user'))
					// console.log('AUTH_TOKEN', AUTH_TOKEN)
					if(AUTH_TOKEN) {
						axios.defaults.headers.common['Authorization'] = 'Token ' + AUTH_TOKEN.token;
					}
					
				//Load User Specific Data
				let fullUserProfile = await dispatch('LOADUserData', userData)
				// console.log("fullUserProfile", fullUserProfile);
				if(fullUserProfile != undefined && Object.keys(fullUserProfile).length != 0) {

					commit("SET_PREFETCH_SUCCESS", true)
				} else {
					commit("SET_PREFETCH_SUCCESS", false)
				}

				if(userData.is_superuser) {
					//SET Global Data
					await dispatch("LOADGlobalData")
				}

				// rootState.Notifications.isLoadPanelVisible = false
				resolve(fullUserProfile)

			}).catch(error => {
				// console.error('preFetchLogin promise error', error)
				// console.error('preFetchLogin promise error', error.message)
			})
			
		},
		async login ({ dispatch, commit, state, rootState }, credentials) {
			// console.log('credentials', credentials)

			let endpoint = 'login/';
			let type = 'Login';
			let response = await apiRoutes.POSTItem(dispatch, rootState, credentials, endpoint, type)
			// console.log('Login response', response)
			if (response.status === 200) {

				rootState.Notifications.isLoadPanelVisible = true
				commit('SET_USER_LOGIN', response.data)

				let AUTH_TOKEN = JSON.parse(localStorage.getItem('user'))
				// console.log('AUTH_TOKEN', AUTH_TOKEN)
				if(AUTH_TOKEN) {
					axios.defaults.headers.common['Authorization'] = 'Token ' + AUTH_TOKEN.token;
				}
				// console.log("state.preFetchSuccess", state.preFetchSuccess);
				
				let userObj = await dispatch("GETUserProfile")
				// console.log("userObj", userObj);
				if(Object.keys(userObj).length != 0 && state.preFetchSuccess == false) {
					// console.log("Object.keys(userObj).length != 0 && state.preFetchSuccess == false");
					let fullUserProfile = await dispatch('LOADUserData', userObj)

					if(userObj.is_superuser) {
						//SET Global Data
						await dispatch("LOADGlobalData")
					}

					// console.log("fullUserProfile",fullUserProfile);

					if(fullUserProfile == "Account Locked" || fullUserProfile == "Account Closed") {
						return
					}
				}

				//Route User to Home Page
				// console.log("window.location", window.location.pathname)
				router.replace({name: 'Secured', query:window.location.pathname}).catch(error => {
					// console.error('Logging in Navigation error', error)
				})

				rootState.Notifications.isLoadPanelVisible = false

			} else {
				rootState.Notifications.isLoadPanelVisible = false
				type = 'Login Unsuccessful'
				dispatch('updateNotification', response);
			}
		},
    
		GETUserProfile({ dispatch, commit, state, rootState }) {
			return new Promise(async (resolve, reject) => {
				
				// console.log('GETUserProfile', state.userProfile)
				// console.log('Object.keys(state.userProfile).length', Object.keys(state.userProfile).length)
				if(state.userProfile && Object.keys(state.userProfile).length === 0) {
					commit('FIND_USER_IN_STORAGE')
					// console.log('state.foundLocalStorageUser', state.foundLocalStorageUser)

					if(state.foundLocalStorageUser) {
						commit('SET_USER_LOGIN', state.foundLocalStorageUser)
						const userProfile = await dispatch('GETUserProfileById', state.foundLocalStorageUser)
						// console.log('userProfile', userProfile)				
						commit('SET_USER_PROFILE', userProfile)

						if(userProfile) {
							commit('SET_USER_LEVEL')
							dispatch('UpdateUserAbility', state, {root:true})

							resolve(userProfile)
						}else {
							commit('LOG_OUT_USER')
							resolve()
						}
						
					} else {
						commit('LOG_OUT_USER')
				
						resolve()
					}
				} else {
					resolve(state.userProfile)
				}
				
			})
			
		},
		async LOADGlobalData({ dispatch, commit, state, rootState }) { // ONLY CALL IF USER = SUPERUSER
			return new Promise(async(resolve, reject) => {
				// console.log('LOADGlobalData')
				//Get list of all SuperUsers
				let superUserList = await dispatch('GETSuperUserList')
				// console.log('superUserList', superUserList)
				// dispatch("GETEmailTemplateList")
				
				
				//Get List of All Merchants
				let merchantList = await dispatch('GETMerchantList')
				// console.log('merchantList', merchantList)

				//Save to Node and Use to send emails
				// dispatch("SetEmailSettings")
				resolve()
			})
		},
		async LOADUserData({ dispatch, commit, state, rootState }, profile) {
			return new Promise(async(resolve, reject) => {
				// console.group('LOADUserData profile', profile)
				//Load User Profile by Hierarchy
				var response, merchantObj
				if(profile.is_superuser) {
					response = await dispatch("FILTERSuperUserProfileById", profile)
					commit('SET_FULL_PROFILE', response)
					// console.log('state.fullUserProfile', state.fullUserProfile)

					//Get List of All Merchants
					let employeeList = await dispatch('GETEmployeeList')
					// console.log('employeeList', employeeList)
					
				} else if(profile.is_merchant) {
					response = await dispatch("FILTEREmpoyeeProfileById", profile)
					// console.log('response', response)
					commit('SET_FULL_PROFILE', response)

					//Get User's Merchant Specific Data
					// console.log('state.fullUserProfile', state.fullUserProfile)
					merchantObj = await dispatch('GETMerchantProfileById', {id: response.merchant})
					// console.log('merchantObj', merchantObj)

					// Check to see if merchant account is locked or date_closed
					if(!merchantObj.is_active) {
						router.replace({name: 'Account Closed'}).catch(error => {
							// console.error('Logging in Navigation error', error)
						})
						dispatch("clearData")
						dispatch('clearData')
						commit('LOG_OUT_USER')
						resolve("Account Closed")
					} else if(merchantObj.is_locked) {
						router.replace({name: 'Account Locked'}).catch(error => {
							// console.error('Logging in Navigation error', error)
						})
						dispatch("clearData")
						dispatch('clearData')
						commit('LOG_OUT_USER')
						resolve("Account Locked")
					}

					//Merchant Employees
					let employees = await dispatch("GETMerchantEmployeeList", {id: response.merchant})
					// console.log('employees', employees)
					
					//API Keys
					// let APIKey = dispatch("GETAPIKeyById", {id: response.merchant})
					// console.log('APIKey', APIKey)

					//Transactions
					let Transactions = await dispatch("FILTERTransactionsByMerchantId", {id: response.merchant})
					// console.log('Transactions', Transactions)
				}

				resolve(response)
			})
		},
		async LOADUserMerchantData({ dispatch, commit, state, rootState }, id ) {
			return new Promise(async (resolve, reject) => {
				// console.log('LOADUserMerchantData profile', id)
				// console.log('state.fullUserProfile', state.fullUserProfile) // Old data, needs to change below
				//Load User Profile by Hierarchy

				var profile = state.fullUserProfile.user_obj

				if(profile.is_merchant) {

					//Get User's Merchant Specific Data
					// console.log('state.fullUserProfile', state.fullUserProfile)
					let merchantObj = await dispatch('GETMerchantProfileById', {id:id})
					// console.log('merchantObj', merchantObj)

					//Merchant Employees
					let employees = await dispatch("GETMerchantEmployeeList", {id:id})
					// console.log('employees', employees)

					//Transactions
					let Transactions = await dispatch("FILTERTransactionsByMerchantId", {id:id})
					// console.log('Transactions', Transactions)
					resolve("Success")
				}
				resolve("Done")
			})
		},
		async setMerchantAccount({ dispatch, commit, state, rootState}, payload) {
			return new Promise(async (resolve, reject) => {
				console.log("Setting Merchant Account gloabally", payload);
				try{
					//Get Selected Merchant's Data
					let merchantObj = await dispatch('GETSelectedMerchantById', payload, {root: true})
					console.log('merchantObj', merchantObj)
					
					//Merchant Employees
					let employees = await dispatch("GETSelectedMerchantEmployeeList", payload, {root: true})
					console.log('employees', employees)
					
					//API Keys
					// let APIKey = await dispatch("GETAPIKeyById", payload)
					// console.log('APIKey', APIKey)
					
					let selectedTransactions = await dispatch("SetTransactionsListByMerchantId", payload, {root: true})
					console.log('auth selectedTransactions', selectedTransactions)
					
					resolve("Success")

				} catch(error) {
					resolve('Error')
				}
				
			})
			
		},
		clearData({ dispatch, commit, state, rootState}, payload) {
			// Need to clear data in Vuex State
			dispatch("clearEmployeeState")
			dispatch("clearMerchantState")
			commit("CLEAR_USER_PROFILE")
			dispatch("clearTransactionState")
		},
    logout({ dispatch, commit, store }) {
			// console.log("User Loged out")
			dispatch('clearData')
			commit('LOG_OUT_USER')
			router.push('/')
    }
  },
  getters: {
    IS_AUTHENTICATED(state) {
      return state.isAuthenticated
		},
		GET_USER_PROFILE(state) {
			// console.log("state.userProfile", state.userProfile);
			return state.userProfile
		},
		GET_FULL_PROFILE(state) {
			// console.log("state.fullUserProfile", state.fullUserProfile);
			return state.fullUserProfile
		},
		GET_USER_LEVEL(state) {
			return state.userLevel
		}

  }
}