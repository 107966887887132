// Import Vue
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// console.log("Process", process)
// console.log("Process.env", process.env)
// console.log("axios.defaults.baseURL", axios.defaults.baseURL)


const location= window.location.hostname
// console.log('location', location)
const domainArray = location.split(".") // [datacom, cardpaymentportal, com]

// if(domainArray[2] == 'com') {
// 	const ending = domainArray[2]
// 	console.log("ending", ending);
// 	const baseDomain = domainArray[1]
// 	console.log('baseDomain', baseDomain)
// 	const fullDomain = baseDomain.concat(".", ending)
// 	console.log("fullDomain", fullDomain);

// 	axios.defaults.baseURL = "https://"+fullDomain;
// } 

// let AUTH_TOKEN = JSON.parse(localStorage.getItem('user'))
// console.log('AUTH_TOKEN', AUTH_TOKEN)
// if(AUTH_TOKEN) {
// 	axios.defaults.headers.common['Authorization'] = 'Token ' + AUTH_TOKEN.token;
// }
