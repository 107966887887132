import apiRoutes from '@/js/api-routes';

export const APIKeys = {
	namespace: true,
	state: {
		elavonAPIKeyList: null,
		elavonAPIKeyProfile: {},
		tinyMCE: "d0zmpecebl97qmojmd806g5qb8lctww31wxmjwawaxlm6n0i",
		
	},
	mutations: {
		PUSH_ELAVON_API_KEY(state, payload) {
			state.elavonAPIKey = payload
		},
		SET_API_KEY(state, payload) {
			state.elavonAPIKeyProfile = {...payload}
		}


	},
	actions: {
		//POST Elavon API Key
		async POSTElavonAPIKey({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				try {
					let endpoint = 'elavon-api-key/';
					let type = 'Create Elavon API Key';
					let response = await apiRoutes.POSTItem(dispatch, rootState, payload, endpoint, type);
					// console.log('POSTElavonAPIKey response', response);
					commit('PUSH_ELAVON_API_KEY', response.data);
					// console.log('rootState', rootState)
					if(response.status === 201) {
						rootState.Notifications.alert = {title: "Success", body: "Created / Saved"}
						rootState.Notifications.showAlert = true
						resolve(response)
					} 

				} catch (error) {
					// console.error("POSTElavonAPIKey axios error", error);
					reject(response)
				}
			}).catch(error => {
				// console.error("POSTElavonAPIKey Promise error", error);
				return error;
			});
		},
		//FILTER API Key By merchant_id
		GETAPIKeyById({commit, dispatch, rootState}, payload) {
			return new Promise( async (resolve, reject) => {
				let endpoint = 'elavon-api-key/?merchant__id=';
				let type = 'Get Elavon API Key';
				let response = await apiRoutes.FILTERListById(dispatch, rootState, payload, endpoint, type);
				// console.log('GETAPIKeyById response', response);
				if(response.status === 200) {
					commit('SET_API_KEY', response.data[0]);
					resolve(response);
				} else {
					reject(response)
				}
			}).catch(error => {
				// console.error("GETAPIKeyById Promise error", error);
				return error;
			});
		},

	},
	getters: {
		RETURN_ELAVON_API_KEY(state) {
			return state.elavonAPIKeyProfile
		}

	
	}
}