<template>
	<div class="flex justify-center">
		<div class="container">
			<div class="headline-3">Merchant Dashboard</div>

			<!-- Select Linked Accounts -->
			<div class="row justify-center" v-if="GET_MERCHANT_PROFILE.linked_account && GET_MERCHANT_PROFILE.linked_account.length > 0">
				<div class="col-3">
					<v-select 
						:items="allAccounts"
						item-text="dba_name"
						item-value="id"
						@change="changeMerchantAccount"
						label="Select Merchant Account"
						v-model="selectedAccount"
					 />
				</div>
			</div>
			<div class="row justify-center">
				<div class="col-6 text-center">
					<div class="headline">
						{{ GET_SELECTED_MERCHANT_PROFILE.dba_name || GET_MERCHANT_PROFILE.dba_name }}
					</div>
				</div>
			</div>
			<!-- Main Container Row -->
			<div class="row">
				<!-- Main Content Left Area -->
				<div class="col-12">
					<template>
						<div class="row mt-4 justify-center">
							<v-card
								class="p-4 mx-6 my-4"
								max-width="350"
								elevation="5"
							>
								<div class="row mt-2">
									<div class="col-12 p-0 flex justify-center">
										<div class="dashboard-number">
											{{ totalTransactions }}
										</div>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12 p-0 text-center">
										<div class="headline-5">
											Transactions
										</div>
									</div>
								</div>
							</v-card>

							<v-card
								class="p-4 mx-6 my-4"
								max-width="350"
								elevation="5"
							>
								<div class="row mt-2">
									<div class="col-12 p-0 flex justify-center">
										<div class="dashboard-number">
											{{ totalRevenue | dollar }}
										</div>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12 p-0 text-center">
										<div class="headline-5">
											Revenue (Last 50 transactions)
										</div>
									</div>
								</div>
							</v-card>

							<v-card
								class="p-4 mx-6 my-4"
								max-width="250"
								elevation="5"
							>
								<div class="row mt-2">
									<div class="col-12 p-0 flex justify-center">
										<div class="dashboard-number">
											{{ totalEmployees }}
										</div>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12 p-0 text-center">
										<div class="headline-5">
											Employees
										</div>
									</div>
								</div>
							</v-card>


						</div>
					</template>
		
				</div>

				<v-row>
					<v-btn 
						@click="testButton"
						color="primary">
						Test
					</v-btn>
				</v-row>

		

			</div>
			<!-- END Main Container Row -->
			<!--Bottom Row -->

		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

export default {
  name: 'merchantDashboard',
  components: {
  },
  mixins: [

  ],
  props: {

  },
  data () {
    return {
			// Main Data
			allAccounts: [],
			totalRevenue: null,
			totalTransactions: null,
			totalEmployees: null,
			
   
    }
  },
  methods: {
    testButton () {
			// console.log("this.GET_TRANSACTION_LIST", this.GET_TRANSACTION_LIST)
			// console.log("this.GET_MERCHANT_PROFILE", this.GET_MERCHANT_PROFILE)
			// console.log("this.GET_MERCHANT_EMPLOYEES_LIST", this.GET_MERCHANT_EMPLOYEES_LIST)
			// console.log("GET_SELECTED_MERCHANT_PROFILE", this.GET_SELECTED_MERCHANT_PROFILE);
			// console.log("GET_LINKED_LIST", this.GET_LINKED_LIST);
			// console.log("GET_SELECTED_TRANSACTION_LIST", this.GET_SELECTED_TRANSACTION_LIST);
			// console.log("selectedAccount", this.selectedAccount);
		},
		async setAllAccounts() { // Set the linked Accounts
			this.allAccounts.push(this.GET_MERCHANT_PROFILE)
			for(let key in this.GET_MERCHANT_PROFILE.linked_account) {
				// Make API Call to get the data
				let acct = await this.$store.dispatch("GETSelectedMerchantList", {id: this.GET_MERCHANT_PROFILE.linked_account[key]})
				this.allAccounts.push(acct)
			}
		},
		resetVariables() {
			return new Promise(async(resolve, reject) => {
				this.totalRevenue = 0
				this.totalEmployees = 0
				this.allAccounts = []
				resolve("Fields Cleared")
			})

		},
		async changeMerchantAccount(evt) {
		// 	console.log('changeMerchantAccount evt', evt)
		// 	console.log("this.selectedAccount", this.selectedAccount);
			await this.resetVariables()

			this.selectedAccount = evt
			let response = await this.$store.dispatch('LOADUserMerchantData', evt)
			// console.log("response", response);
			
			if(response) {
				this.calcAmounts()
				this.setAllAccounts()
			}
			// console.log("this.selectedAccount", this.selectedAccount);
			
		},
		calcId() {
			// console.log("calcId");
			return this.GET_MERCHANT_PROFILE.id
		},
		calcAmounts() {
			//Set Total Revenue
			// console.log("this.GET_MERCHANT_PROFILE ", this.GET_MERCHANT_PROFILE );

			if(Object.keys(this.GET_MERCHANT_PROFILE).length > 0) {
				this.totalTransactions = this.GET_TRANSACTION_LIST.count || 0
				this.totalEmployees = this.GET_MERCHANT_EMPLOYEES_LIST.length || 0

				if(this.GET_TRANSACTION_LIST.count && this.GET_TRANSACTION_LIST.count >0) {
					// console.log("this.GET_TRANSACTION_LIST", this.GET_TRANSACTION_LIST);
					var filteredList = this.GET_TRANSACTION_LIST.results.filter(item => item.result == 0)
					// console.log("filteredList", filteredList);

					//Reduce function
					this.totalRevenue = filteredList.reduce(addAmounts, 0);
					// console.log('this.totalRevenue', this.totalRevenue)

					function addAmounts(total, num) {
						// console.log('total', total)
						// console.log('num', num)

						if(num.jsonResult != null) {
							return total + parseFloat(num.jsonResult.ssl_amount);
						}
					}
				}
				
			} else {
				//Set total Transactions
				// console.log("else called");
				this.totalTransactions = this.GET_SELECTED_TRANSACTION_LIST.count || 0
				this.totalEmployees = this.GET_SELECTED_MERCHANT_EMPLOYEES_LIST.length || 0

				if(this.GET_SELECTED_TRANSACTION_LIST.count && this.GET_SELECTED_TRANSACTION_LIST.count >0) {
					var filteredList = this.GET_SELECTED_TRANSACTION_LIST.results.filter(item => item.result == 0)
					// console.log("filteredList", filteredList);

					//Reduce function
					this.totalRevenue = filteredList.reduce(addAmounts, 0);
					// console.log('this.totalRevenue', this.totalRevenue)

					function addAmounts(total, num) {
						// console.log('total', total)
						// console.log('num', num)

						if(num.jsonResult != null) {
							return total + parseFloat(num.jsonResult.ssl_amount);
						}
					}
				}			
			}
		}
  },
  computed: {
    ...mapState([]),
    ...mapGetters(["GET_TRANSACTION_LIST", "GET_MERCHANT_EMPLOYEES_LIST", "GET_MERCHANT_PROFILE"]),
		...mapGetters(["GET_MERCHANT_LIST", "GET_SELECTED_MERCHANT_PROFILE", "GET_LINKED_LIST", "GET_SELECTED_TRANSACTION_LIST", "GET_SELECTED_MERCHANT_EMPLOYEES_LIST"]),
		selectedAccount: {
			get() {
				// console.log("selectedAccount get");
				return this.GET_MERCHANT_PROFILE.id
			},
			set(newName) {
				// console.log("selectedAccount set", newName);
				return newName
			}
			
			
		}
  },
  mounted () {
		this.setAllAccounts()
		// console.log('this.$route.name', this.$route.name)
	},
	created() {
		this.calcAmounts()
		
	}
}
</script>
<style scoped lang="scss">

</style>
