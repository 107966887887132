<template>
	<v-card>
		<v-card-title class="bg-primary text-white">
			<v-row class="p-2">  
				<v-col cols=9>
					<div class="title">
						<slot name="title"></slot>
					</div>
				</v-col>
				<!-- <div class="col-3 text-right">
					<div class="offset-header-buttons">
						<v-btn class="mx-2" fab dark color="accent">
							<v-icon dark>mdi-upload</v-icon>
						</v-btn>
					</div>

				</div> -->
			</v-row>
			<div>

			</div>
		</v-card-title>
		<!-- Form -->
		<template>
			<div class="p-5">
				<v-form
					ref="baseUserFormRef"
					v-model="valid"
				>
					<slot name="parent-component" />

					<div class="row">
						<div class="header">
							Personal Details
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<v-text-field
								v-model="userForm.first_name"
								:rules="nameRules"
								label="First Name *"
							></v-text-field>
						</div>
						<div class="col-6">
							<v-text-field
								v-model="userForm.last_name"
								:rules="nameRules"
								label="Last Name *"
							></v-text-field>
						</div>
					</div>
					
					<div class="row">
						<div class="col-6">
							<v-text-field
								v-model="mobile_phone"
								label="Phone *"
								:rules="phoneRules"
								v-mask="'(###) ###-####'"
							></v-text-field>
						</div>
						<div class="col-6">
							<v-text-field
								v-model="fax_phone"
								:rules="faxRules"
								v-mask="'(###) ###-####'"
								label="Fax"
							></v-text-field>
						</div>
					</div>
					<div class="row">
						<div class="header mt-5">
							Address Details
						</div>
					</div>
					<div class="row">
						<div class="col-8">
							<v-text-field
								v-model="userForm.address"
								label="Address *"
								:rules="requiredRule"
							></v-text-field>
						</div>
						<div class="col-4">
							<v-text-field
								v-model="userForm.address2"
								label="Address 2"
							></v-text-field>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<v-text-field
								v-model="userForm.city"
								label="City *"
								:rules="requiredRule"
							></v-text-field>
						</div>
						<div class="col-4">
							<v-select
								v-model="userForm.state"
								:items="states"
								item-text="state"
								item-value="abbr"
								persistent-hint
								label="State *"
							></v-select>

							<!-- <v-text-field
								v-model="userForm.state"
								v-mask="'AA'"
								label="State *"
								:rules="requiredRule"
							></v-text-field> -->
						</div>
						<div class="col-4">
							<v-text-field
								v-model="userForm.zip_code"
								v-mask="'#####'"
								label="Zip Code *"
								:rules="requiredRule"
							></v-text-field>
						</div>
					</div>
					<div class="row">
						<div class="header">
							Account Notes
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<v-textarea
							label="Notes"
								name="Account Notes"
								v-model="userForm.notes"
							></v-textarea>
						</div>
					</div>
					<div class="row">
						<div class="header">
							Profile Image
						</div>
					</div>

					<slot name="profile-info" />
				</v-form>

				<!--Reset password -->
				<template v-if="userForm.id">	
					<div class="row my-3">
						<div class="header">
							Login Details
						</div>
						<div class="col-12">
							<v-btn
								width="100%"
								color="secondary"
								@click="resetPassword">
								Reset Password
							</v-btn>
						</div>
					</div>
				</template>

				<template v-else>
					<div class="row">
						<div class="header">
							Login Details
						</div>
					</div>
					
					<v-form
						ref="passwordFormRef"
						v-model="validPW">
						
						<div class="row">
							<div class="col-12">
								<v-text-field
									v-model="userForm.email"
									:rules="emailRules"
									label="E-mail *"
								></v-text-field>
							</div>
						</div>

						<div class="row">
							<div class="col-6">
								<v-text-field
									v-model="userForm.password"
									:rules="[rules.required, rules.min1Letter, rules.min1Num, rules.min6Chars,]"
									label="Password *"
									:type="showPW ? 'text' : 'password'"
									:append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showPW = !showPW"
									hint="At least 8 characters, Min 1 Letter, Min 1 Number"
								></v-text-field>
							</div>
							<div class="col-6">
								<v-text-field
									v-model="confirmPassword"
									:rules="passwordRules"
									label="Confirm Password *"
									type="password"
								></v-text-field>
							</div>
						</div>
						<div v-if="!userForm.id" class="row">
							<div class="col-12">
								<v-alert
									v-if="comparePasswords"
									text
									type="error"
									icon="mdi-information"
									>
									Passwords do not match
								</v-alert>
							</div>
						</div>
					</v-form>
				</template>
				

				<div class="row justify-end my-4">
					<div class="col-3 text-right" v-if="$can('view', 'superuser')">
						<v-btn
							color="warning"
							class="mr-4"
							@click="testButton"
						>
							Test
						</v-btn>
					</div>
					<div class="col-3 text-right">
						<v-btn
							color="error"
							class="mr-4"
							@click="resetForm"
						>
							Reset
						</v-btn>
					</div>
					<div class="col-3 text-right">
						<v-btn
							color="success"
							class="mr-4"
							@click="sendToParent"
						>
							Submit
						</v-btn>
					</div>
				</div>
				
			</div>

		</template>
		<!-- END Form -->
	</v-card>
</template>

<script>
"use strict";
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'
import superusersVue from '../../pages/superuser-portal/superusers.vue'

import { stateNames } from "@/js/states.js"

export default {
  name: 'baseUserFormComponent',
  components: {
  },
  mixins: [
    FormMixins
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			showPW: false,
			confirmPassword: "",
			valid: false,
			validPW: false,
			

      // Form Data
      states: stateNames 
    }
  },
  methods: { 
    testButton () { 
			// console.log('this.userForm', this.userForm)
			// console.log('this.confirmPassword', this.confirmPassword)
			// console.log("GET_EMPLOYEE_LIST", this.GET_EMPLOYEE_LIST);
			// console.log("GET_MERCHANT_EMPLOYEES_LIST", this.GET_MERCHANT_EMPLOYEES_LIST);
			// console.log("GET_EMPLOYEE_PROFILE", this.GET_EMPLOYEE_PROFILE);

		},
		async sendToParent() {
			// console.log('this.valid', this.valid)
			this.$refs.baseUserFormRef.validate()
			//If form Id == selected_merchant.id then perform update PATCH
			// console.log('this.GET_SELECTED_USER_PROFILE', this.GET_SELECTED_USER_PROFILE)
			// console.log('this.userForm', this.userForm)

			this.mobile_phone ? this.userForm.mobile_phone = this.mobile_phone.replace(/\D/g,''): null
			this.fax_phone ? this.userForm.fax = this.fax_phone.replace(/\D/g,''): null

			// console.log("this.$parent", this.$parent);
			

			//Superuser or Employee???
			if(this.$parent.$parent.$refs.baseSuperUserFormRef) {
				//Superuser First
				if(this.userForm.id === this.GET_SELECTED_USER_PROFILE.user) { // Is this an edit of the selected profile
					//Perform PATCH
					if(this.valid) {
						//Update Base User Firsts
						let response = await this.$store.dispatch('PATCHBaseUser', this.userForm);
						if(response.status === 200 || response.status === 201 ) {
							//Update SU Instance
							this.$emit("updateSuperuser", response.data)
							this.userForm.id = null
							this.resetForm()
						}
					}
				} else { // Not edit, create new
					//POST superusersVue//Create Superuser
					this.$refs.passwordFormRef.validate()
						if(this.validPW) {
							// this.$emit('SetUserData', this.userForm)
							// await this.$parent.$parent.SetUserData(this.userForm)
							await this.$parent.$parent.createUser(this.userForm)
							this.resetForm()
						}
					
				}
				//Employee Section
			} else {
					//PATCH
					if(this.userForm.id === this.GET_SELECTED_USER_PROFILE.user) { // Edit user
						if(this.valid) {
							let response = await this.$store.dispatch('PATCHBaseUser', this.userForm);
							if(response.status === 200 || response.status === 201 ) {
								//Update Employee Instance
								this.$emit("PATCHEmployee", response.data)
								this.userForm.id = null
								this.resetForm()
							}
						}
					} else {
						//POST New Employee
						if(this.valid) {
							this.$refs.passwordFormRef.validate()
							if(this.validPW) {
								this.$emit('SetUserData', this.userForm)
								// console.log("this.userForm", this.userForm);
								await this.$parent.$parent.SetUserData(this.userForm)
								this.$parent.$parent.createUser();
								// this.resetForm() 
							}
						}
					}
				}

		},
		//Update Form Fields from Parent and from datatable
		async updateForm(evt) {
			// console.log("base userform updateForm", evt)
			//Full Object passed in

			//Map to existing form
			for(let key in this.userForm) {
				// console.log('key', key)
				this.userForm[key] = evt.user_obj[key]
			}
			let newPhone = await this.parsePhoneNumber(evt.user_obj.mobile_phone)
			this.mobile_phone = newPhone
			// console.log('newPhone', newPhone)
		},
		resetForm() {
			this.resetFormData("baseUserFormRef")
			// console.log("this.userForm.", this.userForm);

			this.$refs.baseUserFormRef.reset()
			this.$refs.baseUserFormRef.resetValidation()
			this.$refs.passwordFormRef.reset()
			this.$refs.passwordFormRef.resetValidation()
			this.userForm.groups = []

			// emit an action to disable the checkbox from the datatable
			this.$emit("clearCheckbox")
			
		},
		parsePhoneNumber(evt) {
			return new Promise((resolve, reject) => {
				// console.log('evt', evt)
				try {
					if(evt) {
						var areaCode, prefix, ending
						areaCode = evt.slice(0,3)
						prefix = evt.slice(3,6)
						ending = evt.slice(6)
						resolve("(" + areaCode + ")" + " " + prefix + "-" + ending)
					} else {
						return
					}
					
				} catch (error) {
					// console.log('error', error)
				}
			})
		},
		async resetPassword() {
			// console.log("resetPassword this.GET_SELECTED_USER_PROFILE", this.GET_SELECTED_USER_PROFILE)
			let user = {
				email: this.userForm.email,
				first_name: this.GET_SELECTED_USER_PROFILE.first_name
			}
			let email = await this.$store.dispatch('passwordResetEmail', user);
			// console.log('email', email)
			if(email) {
				this.$store.dispatch('notificationPopup', {title: "Success", body: "Email Sent"});
			} else {
				this.$store.dispatch('notificationPopup', {title: "Error", body: "There was an error processing your request"});
			}
		},

  },
  computed: {
    ...mapState([]),
		...mapGetters(["GET_SELECTED_USER_PROFILE", "GET_EMPLOYEE_LIST", "GET_MERCHANT_EMPLOYEES_LIST", "GET_EMPLOYEE_PROFILE" ]),
		

  },
  mounted () {

  },
	unmounted() {

	}
}
</script>
<style scoped lang="scss">

</style>
